import React, { Component } from 'react';
import styles from './index.module.css';
import { Select, Input, DatePicker, Button, Checkbox, Table, Radio, message, Modal, InputNumber, Image, Popconfirm } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import IndexModal from './indexModal';
import {
  getOrderList, postOrderSecond, postOrderClaim, getExcel, getOrder,
  putUrgecontact, orderToEacel, delDraft, putBonus
} from '../../services/orderRenter';
import { getCoupon, postOrder } from '../../services/newOder';
import { postNewOrder } from '../../services/product';
import { EyeOutlined, RightOutlined } from '@ant-design/icons'
import { getDepartment_Data, setBalance } from '../../config/storage';
import UrgeOrder from '../../resource/png/urge_order.png';
import UrgeOrder1 from '../../resource/png/urge_order1.png';
import _ from 'lodash';
import moment from 'moment';
moment.locale('zh-cn');
import { connect } from 'dva';
import PageTurning from '../../components/pageTurning';
import AppointModal from './appointModal';
import ReminderModal from './reminderModal';
import OrderModal from '../productOrder/orderModal';
import { getFinancebase } from '../../services/finance';
import Link from '../../resource/png/link.png'
import Tong from '../../resource/png/tong.png'
import { jsToExcel, exportExcel } from '../../utils/exportExcel';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
const states = ['待审核', '已通过', '已拒绝'];
const states2 = ['已通过', '已拒绝'];

// const choiceType = ['全部订单', '窗帘', '晾衣架', '墙纸墙布', '其他', '销售订单'];
const orderType = ['全部类型', '测量订单', '安装订单'];
const orderState = ['全部状态', '草稿箱', '待分配', '待接单', '待预约', '预约失败', '待上门', '已签到', '已完成', '已终止'];
const radioQuery = ['全部订单', '二次上门', '售后订单', '好评返现', '加急订单', '催联系订单', '终止订单']
const getWeek = (e) => {
  let day = new Date(e).getDay() + 1;
  let date = '';
  switch (day) {
    case 1: date = '周一'; break;
    case 2: date = '周二'; break;
    case 3: date = '周三'; break;
    case 4: date = '周四'; break;
    case 5: date = '周五'; break;
    case 6: date = '周六'; break;
    case 7: date = '周日'; break;
  }
  return date;
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
      pageEdit: false,

      switchType: false,

      visible: false,
      visibleTurnOrder: false,
      confirmLoading: false,
      secondID: 0,
      turnOrderData: {},

      modalType: '',
      afterSaleType: '申请理赔',
      afterService: '',
      afterSalePrice: 0,
      orderServiceID: 0,
      startDates: startDate,
      endDates: endDate,
      radioType: '好评返现',
      textAreaValue: '',
      choiceType: [],
      orderService: [],
      choiceTypes: '全部订单',
      orderTypes: '全部类型',
      orderStates: '全部状态',
      checbox: [
        { label: '正常订单', value: '正常订单', type: true },
        { label: '二次上门', value: '二次上门', type: true },
        { label: '售后订单', value: '售后订单', type: true },
      ],
      data: [],
      couponData: [],
      couponID: 0,
      customerValue: '',
      state: '待审核',

      skip: 0,
      take: 50,
      twiceReason: '',
      bonusModal: {
        pic: [],
        remark: '',
        visible: false,
        state: '已通过',
        price: 15,
        id: 0,
      },
    }
    this.pageSize = 10;
    this.tempPageIndex = 1;
    this.indexModal = React.createRef();
    this.reminderModal = React.createRef();
    this.appointModal = React.createRef();
    this.orderModal = React.createRef();
    this.baseUrl = getDepartment_Data().service;
    this.salePermiss = getDepartment_Data().sale;
    this.role = getDepartment_Data().role;

    this.columns = [
      {
        title: '业务订单信息',
        align: 'center',
        width: 70,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`}>
              <span className={`${styles['columns-span']}`}>{record.id}</span>
              <span className={`${styles['left-top-type']}  ${record.service === '安装' ? 'background1' : 'background2'}`}><div className={styles['left-top-type-span']}>{record.service}</div></span>
              <span className={`${styles['columns-span']} margin-t5`}>{record.category + '-' + record.type}</span>
              <span className={`${styles['columns3-type-span']} margin-t5`}>【 {record.state} 】</span>
            </div>
          )
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 80,
        render: (text, record, index) => {
          return (
            <div className='col'>
              <div className={`${styles['remack-overflow']} col`}>
                {record.urge_contact === '是' &&
                  <span className={styles['urgeOrder-type']}>
                    <div className={styles['left-top-type-span']}>催单</div>
                  </span>}
                {record.urge_order === '是' &&
                  <div className={styles['urgeOrder-type-right']}>
                    <div className={styles['right-top-type-span']}>加急</div>
                  </div>}
                <div className={styles['remack-overflow-width']}>
                  <div className={`${styles['columns-span']}`}>
                    <img src={Link} onClick={this.onCopyToClip.bind(this, record)} />
                    {record.name.split('|')[0]} {record.phone}
                  </div>
                  <div className={`${styles['columns-span']} margin-t5`}>{record.province + record.city + record.district + record.address}</div>
                </div>
                {
                  this.isReminder(record.serviceFollows).length > 0 &&
                  <div className='row'><div onClick={this.reminderShow.bind(this, this.isReminder(record.serviceFollows))} className={`${styles['columns2-reminder-button']} margin-t10 `}>查看催单记录</div></div>
                }
                {
                  record.service === '安装' && record.state === '待收款' ||
                    record.service === '安装' && record.state === '待审核' ||
                    record.service === '安装' && record.state === '已完成' ||
                    record.service === '安装' && record.state === '已终止' ? '' :
                    <div className='row'>
                      {
                        record.urge_contact === '是' ? (
                          <div></div>
                        ) : (
                          <div className='row'><div className={styles['columns2-copy-button']} onClick={this.onReminder.bind(this, record.id)}>发起催单</div></div>
                        )
                      }
                    </div>
                }
              </div>
              {
                record.mutil_order === '是' &&
                <Image src={Tong} className={styles['tong2']} preview={false} />
              }

            </div>
          )
        }
      },
      {
        title: '下单信息',
        align: 'center',
        width: 80,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col tc wsn`}>
              <div className={`color3 font-underline margin-t5`}>{record.shopName}</div>
              <div className={`${styles['columns-span']} margin-t5`}>{record.create_time}</div>
              <div className={styles['columns1-date-span']}>(下单时间)</div>
              <div className={`${styles['columns-span']} margin-t5`}>下单人: {record.operator}</div>
              {record.order_way !== '客户下单' && <span className={`${styles['abnormal_div']} margin-t5`}>{record.order_way}</span>}
            </div>
          )
        }
      },
      {
        title: '商家备注',
        align: 'center',
        width: 70,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`}>
              <span className={`${styles['columns-span']} ${styles['remack-overflow']}`}>{record.shop_remark}</span>
              {
                record.name.split('|')[1] &&
                <span className={styles['columns-span']}>淘宝订单号:{record.name.split('|')[1]}</span>
              }
            </div>
          )
        }
      },
      {
        title: '官方客服备注',
        align: 'center',
        width: 70,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col `}>
              <span className={`${styles['columns-span']} ${styles['remack-overflow']} color2`}>{record.service_shop_remark}</span>
            </div>
          )
        }
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 70,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`}>
              <span className={styles['columns-span']}>{record.workerName ? record.workerName.slice(0, 1) + '师傅' : ''}</span>
              <span className={styles['columns-span']}>{record.workerPhone}</span>
            </div>
          )
        }
      },
      {
        title: '预约信息',
        align: 'center',
        width: 70,
        render: (text, record, index) => {
          let appointList = record.serviceFollows.filter(item => item.type === '预约')
          let result = '--';
          if (appointList.length === 1) {
            result = appointList[0];
          } else if (appointList.length > 1) {
            appointList.sort((a, b) => {
              return b.id - a.id > 0;
            });
            result = appointList[appointList.length - 1]
          }
          return (
            <div className={`${styles['columns-border']} col`}>
              {
                (record.appoint_time && record.appoint_time !== '0001-01-01T00:00:00') ? (
                  <div className={styles['columns-span']}>
                    {moment(record.appoint_time).subtract(30, 'm').format('YYYY/MM/DD')}
                    {getWeek(record.appoint_time)}
                  </div>
                  // <div className={styles['columns-span']}>{this.removeT(record.appoint_time)}</div>
                ) : (
                  <div></div>
                )
              }
              <span className={styles['columns1-date-span']}>(预约时间)</span>
              {
                appointList.length > 0 &&
                <span onClick={this.appointRecord.bind(this, appointList)} className={'fz14 color3 cursor-pointer font-underline'}>预约记录</span>
              }
              {/* {
                record.appoint_time !== '0001-01-01T00:00:00' && <div className='col'>
                  <span className={styles['columns-span']}>{record.appoint_time}</span>
                  <span className={styles['columns1-date-span']}>(预约时间)</span>
                </div>
              } */}
              {record.bonus_order === '是' && <img src={UrgeOrder} className={'margin-t10'} />}
            </div>
          )
        }
      },
      {
        title: '订单金额',
        align: 'center',
        width: 50,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`} key={index}>
              <span>{record.price_app}</span>
            </div>
          )
        }
      },
      {
        title: '订单操作',
        align: 'center',
        width: 80,
        render: (text, record, index) => {
          return (
            <div className={` row-w margin-l10 w100`} key={index}>
              <div className={` ${styles['button-color1']} ${styles['style-button']}`}
                onClick={this.onOpenModal.bind(this, record, '订单详情')}
              >订单详情</div>
              {
                record.state !== '待审核' &&
                record.state !== '已完成' &&
                record.state !== '已终止' &&
                record.state !== '待开发' && false &&
                <div className={` ${styles['button-color1']} ${styles['style-button']}`}
                  onClick={this.onOpenModal.bind(this, record, '修改订单')}
                >修改订单</div>
              }
              <div className={` ${styles['button-color1']} ${styles['style-button']}`}
                onClick={this.onSecondDoor.bind(this, record, '二次上门')}
              >二次上门</div>
              {
                record.state === '已完成' && ['正常订单', '二次上门'].includes(record.type) &&
                <div className={` ${styles['button-color1']} ${styles['style-button']}`}
                  onClick={this.onSecondDoor.bind(this, record, '申请售后')}
                >申请售后</div>
              }
              {
                (
                  record.state !== '待预约' &&
                  record.state !== '草稿箱' &&
                  record.state !== '待接单' &&
                  record.state !== '待分配'
                ) && <div className={` ${styles['button-color2']} ${styles['style-button']}`}
                  onClick={this.onSecondDoor.bind(this, record, '投诉师傅')}
                >投诉师傅</div>
              }
              {
                this.salePermiss === '是' &&
                <div className={` ${styles['button-color4']} ${styles['style-button']}`}
                  onClick={this.chargeProduct.bind(this, record)}
                >转杆轨单</div>
              }
              {
                record.bonus_state === '待审核' && record.state === '已完成' && record.bonus_type === '不固定金额' &&
                <div className={` ${styles['button-color4']} ${styles['style-button']}`}
                  onClick={this.checkBonus.bind(this, record)}
                >好评审核</div>
              }
            </div>
          )
        }
      },
    ]
  }

  componentDidMount() {
    let shopUsers = getDepartment_Data().shopUsers;
    shopUsers.unshift(
      {
        id: 0,
        name: '全部'
      },
      {
        id: getDepartment_Data().id,
        name: getDepartment_Data().userName,
      }
    )
    // let service = getDepartment_Data().serviceInfo.service;
    let service = getDepartment_Data().service_type === '' ? [] : JSON.parse(getDepartment_Data().service_type);
    service.unshift('全部订单')
    let orderServiceID = shopUsers[0].id;
    if (this.role === '加盟商') {
      orderServiceID = getDepartment_Data().id;
    }
    this.setState({
      choiceType: service,
      orderService: shopUsers,
      orderServiceID,
    }, () => this.getList())
  }

  render() {
    const {
      orderServiceID, startDates, state, bonusModal,
      endDates, radioType,
      choiceTypes, orderTypes,
      orderStates, data,
      checbox, orderService,
      choiceType, visible, textAreaValue, twiceReason,
      afterSaleType, visibleTurnOrder, afterService,
      couponData, couponID, customerValue,
      take,
    } = this.state;
    return (
      <div>
        <div className={`${styles['bar']} w100 col vertical-lt`}>
          <div className='row-l margin-b10 margin-l10'>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>下单客服:</span>
              <Select
                disabled={this.role === '加盟商'}
                showSearch
                className='w295'
                placeholder="请输入/选择下单客服"
                optionFilterProp="children"
                value={orderServiceID}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                }
                onChange={this.onChangeSelect}
              >
                {
                  orderService.map(item => {
                    return (
                      <Option value={item.id} key={item.id}>{item.name}</Option>
                    )
                  })
                }
              </Select>
            </div>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>审核状态：</span>
              <Radio.Group value={state} onChange={this.changeState}>
                {
                  states.map((item, index) => {
                    return (
                      <Radio value={item} key={index}>{item}</Radio>
                    );
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>下单时间:</span>
              <DatePicker placeholder='开始时间' className='w198' defaultValue={moment(startDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'start')} />
              <div>至</div>
              <DatePicker placeholder='结束时间' className='w198' defaultValue={moment(endDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'end')} />
            </div>
            <div className='row-l margin-l20'>
            </div>
          </div>
          <div className='row-l margin-b10 margin-l10'>
            <div className='row-l margin-r10'>
              <span className='margin-r10'>客户信息:</span>
              <Input value={customerValue} className='w295' placeholder='可输入客户姓名、手机、地址等' onChange={this.onCustomerValue} />
            </div>
            <Button type='primary' className={styles['button_div']} onClick={this.getList}>搜索</Button>
          </div>
        </div>
        <div className='w100 margin-t10 back-color0 '>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            // pagination={{ defaultPageSize: 15 }}
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={take} />
        </div>
        {/*申请售后，投诉 Modal*/}
        <Modal
          title={this.state.modalType}
          visible={visible}
          width={700}
          onCancel={this.onCancel}
          onOk={this.onOk}
          okText='确定'
          cancelText='取消'
          confirmLoading={this.state.confirmLoading}
        >
          {
            this.state.modalType === '二次上门' &&
            <div className='col-t vertical-lt w100 '>
              <div className='row-l w100 vertical-lt'>
                <div className='margin-r10'>预约备注:</div>
                <Input.TextArea value={textAreaValue} rows={4} placeholder="输入预约备注" className={styles['w465']} onChange={this.getTnputTextAteaValue} />
              </div>
              <div className='row-w w100 margin-t10'>
                {
                  this.state.modalType === '二次上门' &&
                  ['货不齐', '缺少配件', '师傅空跑', '测量复尺', '安装窗帘'].map((item, index) => {
                    return (
                      <div onClick={this.twiceFast.bind(this, item)} className={`${styles['quick-remark']} color10`} key={index}>{item}</div>
                    );
                  })
                }
              </div>
            </div>
          }
          {
            this.state.modalType === '申请售后' && <div className='col vertical-lt'>
              <div className='row-l vertical-lt'>
                <div className={`${styles['title-order-cost']} margin-r10`}>售后类型:</div>
                {
                  afterService === '测量' &&
                  <div className={`${styles['choiceType_div']} ${afterSaleType === '售后服务' && styles['choiceType_Color']}`}>
                    售后服务
                  </div>
                }
                {
                  afterService === '安装' && ['申请理赔', '售后服务'].map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`${styles['choiceType_div']} ${afterSaleType === item && styles['choiceType_Color']}`}
                        onClick={this.onAfterSaleType.bind(this, item)}
                      >
                        {item}
                      </div>
                    )
                  })
                }
              </div>
              {afterSaleType === '申请理赔' && <div className='row-l vertical-lt margin-t10'>
                <div className={`${styles['title-order-cost']} margin-r10`} >理赔金额:</div>
                <InputNumber className={styles['w465']} value={this.state.afterSalePrice} mix={0} onChange={this.getInputNumberValue} />
              </div>}
              <div className='row-l vertical-lt margin-t10'>
                <div className={`${styles['title-order-cost']} margin-r10`} >备注:</div>
                <Input.TextArea value={textAreaValue} rows={4} className={styles['w465']} placeholder="输入备注" onChange={this.getTnputTextAteaValue} />
              </div>
            </div>
          }
          {
            this.state.modalType === '投诉师傅' && <div>
              <div className='row-l vertical-lt margin-t10'>
                <div className={`${styles['title-order-cost']} margin-r10`} >投诉内容:</div>
                <Input.TextArea value={textAreaValue} rows={4} className={styles['w465']} placeholder="输入备注" onChange={this.getTnputTextAteaValue} />
              </div>
            </div>
          }
        </Modal>
        <IndexModal
          ref={this.indexModal}
          updateTable={this.updateTable}
        />
        <ReminderModal
          ref={this.reminderModal} />
        <AppointModal
          ref={this.appointModal} />
        <OrderModal ref={this.orderModal} updateList={this.getList} />
        <Modal
          title='好评审核'
          visible={bonusModal.visible}
          width={750}
          onCancel={this.onCancelBonus}
          onOk={this.onOkBonus}
          okText='确定'
          cancelText='取消'
        >
          <div className='col-t vertical-lt w100'>
            <div className='row-l vertical-lt margin-b10'>
              <div className='w120'>师傅好评说明：</div>
              <div>{bonusModal.remark}</div>
            </div>
            <div className='row-l vertical-lt w100 margin-b10'>
              <div className='w120'>师傅好评图片：</div>
              <div className='row-w w590'>
                {
                  bonusModal.pic.map((item, index) => {
                    return (
                      <div key={index} className={`${styles['content']}`}>
                        <img
                          alt="暂无图片"
                          className={`${styles['pic2']}`}
                          src={`${this.baseUrl}/upload/${item}`}
                        />
                        <div className={`row ${styles['content-div2']}`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme="outlined"
                            title="预览"
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <div className='row-l vertical-lt margin-b10'>
              <div className='w120'>审核结果：</div>
              <Radio.Group value={bonusModal.state} onChange={this.changeBonusState}>
                {
                  states2.map((item, index) => {
                    return (
                      <Radio value={item} key={index}>{item}</Radio>
                    );
                  })
                }
              </Radio.Group>
              {
                bonusModal.state === '已通过' &&
                <div className='row-l vertical-lt margin-l20'>
                  <div className='w120'>好评金额：</div>
                  <InputNumber value={bonusModal.price} className='w150' onChange={this.changeBonusPrice} min={15} />
                </div>

              }
            </div>

          </div>
        </Modal>
      </div>
    );
  }
  //客户信息
  onCustomerValue = (e) => {
    this.setState({
      customerValue: e.target.value,
    })
  }
  //二次上门快捷选择
  twiceFast = (e) => {
    let textAreaValue = this.state.textAreaValue + e + ';';
    this.setState({
      textAreaValue
    })
  }
  onTwiceSearch = (e) => {
    this.setState({
      customerValue: e,
      twiceReason: e,
    }, async () => this.getList())
  }
  //审核状态
  changeState = (e) => {
    this.setState({
      state: e.target.value
    });
  }
  //一键转单
  onTurnOrder = async (data) => {
    let res = await getOrder(data.id);
    if (res.status !== 200) {
      message.error('订单数据获取失败');
      return;
    }
    const { dispatch } = this.props;
    res.data.add_app = 0;
    res.data.add_oms = 0;
    res.data.add_remark = '';
    res.data.shop_remark = '';
    dispatch({
      type: 'orderRenter/getNewOrderData',
      payload: res.data,
    });
    this.props.fun('新建订单');
  }
  //催单
  onReminder = async (id) => {
    let res = await putUrgecontact([id]);
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.getList();
  }
  //转杆轨单
  chargeProduct = async (e) => {
    let newData = {
      id: e.id,
      shop: getDepartment_Data().id,
      name: e.name + '客户',
      phone: e.phone,
      province: e.province,
      city: e.city,
      district: e.district,
      address: e.address,
      remark: '',
      rooms: [],
    };
    let res = await postNewOrder(newData);
    if (res.status === 200) {
      let trans = {
        id: res.data.mMorder,
        links: [
          {
            link: res.data.lJorder.id
          }
        ]
      }
      this.getList();
      this.orderModal.current.onShow(trans);
    }
  }
  toProtuct = (item) => {
    let trans = {
      id: item.order,
      links: [
        {
          link: item.link
        }
      ]
    }
    this.orderModal.current.onShow(trans);
  }
  //Excel
  onExcelData = _.throttle(async (id, type) => {
    let res = await getExcel(id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    if (type === '测量数据下载') {
      window.location.href = res.data
    } else {
      let newFileUrl = encodeURIComponent(res.data)
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${newFileUrl}`, '_blank')
    }
  }, 1000)
  //理赔金额
  getInputNumberValue = (e) => {
    this.setState({
      afterSalePrice: e
    })
  }
  //售后类型
  onAfterSaleType = (type) => {
    this.setState({
      afterSaleType: type,
    })
  }
  //预约备注
  getTnputTextAteaValue = (e) => {
    this.setState({
      textAreaValue: e.target.value
    })
  }
  //打开二次上门，申请售后modal
  onSecondDoor = (data, type) => {
    let afterSaleType = '申请理赔';
    if (type === '申请售后' && data.service === '测量') {
      afterSaleType = '售后服务';
    }
    this.setState({
      visible: true,
      secondID: data.id,
      modalType: type,
      afterService: data.service,
      afterSaleType
    })
  }
  onOk = async () => {
    this.setState({
      confirmLoading: true
    })
    let { secondID, textAreaValue, modalType, afterSalePrice, afterSaleType } = this.state;
    let res;
    if (modalType === '二次上门') {
      res = await postOrderSecond(secondID, textAreaValue);
      if (res.status !== 204) {
        message.error(res.data);
        return;
      }
      message.success('已提交申请二次上门');
      this.getList()
    } else {
      if (modalType === '投诉师傅') {
        afterSaleType = '投诉师傅'
      }
      res = await postOrderClaim(secondID, afterSaleType, afterSalePrice, textAreaValue);
      if (res.status !== 204) {
        message.error(res.data);
        return;
      }
      message.success('已提交');
      this.getList()
    }
    this.onCancel()
  }
  //关闭modal
  onCancel = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
      afterService: '',
    })
  }
  //复制
  onCopyToClip = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'姓名:' + data.name + '\n' + '手机号:' + data.phone + '\n' + '地址:' + data.province + data.city + data.district + data.address
      }`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  //修改订单
  updateTable = (row) => {
    // let data = [...this.state.data];
    // let index = data.findIndex(r => r.id === row.id);
    // data[index] = row;
    // this.setState({
    //   data: data
    // })
    this.getList();
    this.getBalances();
  }
  getBalances = async () => {
    let res = await getFinancebase();
    if (res.status === 200) {
      setBalance(res.data.balance)
    }
    const { dispatch } = this.props;
    dispatch({
      type: 'orderRenter/getNewBalance',
      payload: res.data.balance,
    });
  }
  //打开modal
  onOpenModal = (data, type) => {
    this.indexModal.current.onShowModal(data, type);
  }
  //订单状态
  setOrderStates = (e) => {
    this.setState({
      orderStates: e
    }, async () => this.getList())
  }
  //多选框
  onCheckbox = (index, e) => {
    let checbox = [...this.state.checbox];
    checbox[index].type = e.target.checked;
    this.setState({
      checbox: checbox
    })
  }
  //订单类型
  setOrderTypes = (e) => {
    this.setState({
      orderTypes: e
    }, async () => this.getList())

  }
  //选择品类
  onCoice = (e) => {
    let orderTypes = '';
    if (e === '晾衣架') {
      orderTypes = '安装订单'
    } else if (e === '其他') {
      orderTypes = '其他'
    } else {
      orderTypes = '全部类型'
    }
    this.setState({
      choiceTypes: e,
      orderTypes: orderTypes
    }, async () => this.getList())
  }
  //获取数据
  getList = _.throttle(async () => {
    const { orderServiceID, radioType, startDates, endDates, skip, take, customerValue, state } = this.state;
    let res = await getOrderList({
      user: orderServiceID,
      search: radioType,
      info: customerValue,
      start: startDates,
      end: endDates,
      category: '全部',
      service: '全部',
      state: state,
      types: '正常订单',
      skip, take
    });
    if (res.status !== 200) {
      message.error(res.data)
      return;
    }
    this.setState({
      data: res.data
    })
  })
  //快速查询
  setRadioType = (e) => {
    this.setState({
      radioType: e,
      twiceReason: '',
    }, async () => this.getList())
  }
  //时间
  onChangeDate = (type, dates, dateStrings) => {
    if (type === 'start') {
      this.setState({
        startDates: dateStrings
      })
    } else {
      this.setState({
        endDates: dateStrings
      })
    }
  }
  //下单客户
  onChangeSelect = (e) => {
    this.setState({
      orderServiceID: e
    })
  }
  //导出excel   orderToEacel jsToExcel
  toExcel = async () => {
    const { checbox, choiceTypes, orderTypes, orderStates, orderServiceID, radioType, startDates,
      endDates, customerValue } = this.state;
    let types = checbox.map(item => {
      if (item.type) {
        return item.value
      }
    })
    types = types.join('|')
    let newChoiceTypes = choiceTypes === '全部订单' ? '全部' : choiceTypes;
    let newOrderTypes;
    if (orderTypes === '全部类型') {
      newOrderTypes = '全部'
    } else if (orderTypes === '测量订单') {
      newOrderTypes = '测量'
    } else if (orderTypes === '安装订单') {
      newOrderTypes = '安装'
    }
    let newOrderStates = orderStates === '全部状态' ? '全部' : orderStates;
    let res = await orderToEacel({
      user: orderServiceID,
      search: radioType,
      info: customerValue,
      start: startDates,
      end: endDates,
      category: newChoiceTypes,
      service: newOrderTypes,
      state: newOrderStates,
      types,
    });
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let excelData = res.data.map((item, index) => {
      return {
        // key: index + 1,
        info: item.id + item.category + item.service + item.type,
        name: item.name,
        phone: item.phone,
        address: item.province + item.city + item.district + item.address,
        shop: item.operator,
        price: item.price_app,
        status: item.state,
        time: item.create_time,
      }
    })
    const columnsBill = [
      {
        title: '订单信息',
        align: 'center',
        width: 110,
        dataIndex: 'info',
        key: 'info',
      },
      {
        title: '客户姓名',
        align: 'center',
        width: 110,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '客户电话',
        align: 'center',
        width: 110,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '客户地址',
        align: 'center',
        width: 110,
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: '商户名称',
        align: 'center',
        width: 110,
        dataIndex: 'shop',
        key: 'shop',
      },
      {
        title: '商户费用',
        align: 'center',
        width: 110,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '订单状态',
        align: 'center',
        width: 110,
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: '下单时间',
        align: 'center',
        width: 110,
        dataIndex: 'time',
        key: 'time',
      },
    ]
    let fileName = `${startDates}-${endDates}订单列表`;
    // let titleName = `${startDates}到${endDates} 订单记录`;
    let titleName = `${startDates}到${endDates} 订单记录.xlsx`;
    // jsToExcel(columnsBill, excelData, fileName, titleName);
    exportExcel(columnsBill, excelData, titleName)
  }
  //有无催单
  isReminder = (order) => {
    let res = [];
    order.map(item => {
      if (item.type === '催单') {
        res.push(item);
      }
    })
    return res;
  }
  //预约记录显示
  appointRecord = (e) => {
    this.appointModal.current.onShow(e);
  }
  //催单记录显示
  reminderShow = (e) => {
    this.reminderModal.current.onShow(e);
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
  //删除草稿箱
  deleteOrder = async (e) => {
    let res = await delDraft(e.id);
    if (res.status === 204) {
      this.getList();
    }
  }
  checkBonus = async (e) => {
    let pic = e.bonus_pic.includes('[') && e.bonus_pic.includes(']') ? JSON.parse(e.bonus_pic) : [];
    this.setState({
      bonusModal: {
        pic: pic,
        remark: e.bonus_remark,
        visible: true,
        state: '已通过',
        price: 15,
        id: e.id,
      },
    })
  }
  onCancelBonus = () => {
    this.setState({
      bonusModal: {
        pic: [],
        remark: '',
        visible: false,
        state: '已通过',
        price: 15,
        id: 0,
      },
    })
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  changeBonusState = (e) => {
    let bonusModal = { ...this.state.bonusModal };
    bonusModal.state = e.target.value;
    this.setState({
      bonusModal,
    });
  }
  changeBonusPrice = (e) => {
    let bonusModal = { ...this.state.bonusModal };
    bonusModal.price = e ? e : 15;
    this.setState({
      bonusModal,
    });
  }
  onOkBonus = async () => {
    const { bonusModal } = this.state;
    let res = await putBonus(bonusModal.id, bonusModal.state, bonusModal.price);
    if (res.status === 204) {
      this.getList();
      this.onCancelBonus();
    }
  }
}

export default Index;
// export default connect(({ orderRenter }) => (orderRenter))(Index);