import React, { Component } from 'react';
import Regin from '../../components/region';
import { Form, Input, message, Spin, Radio, Checkbox, DatePicker, Table, Button, InputNumber, Upload, Select, Modal, TimePicker, Popconfirm } from 'antd';
import { analysisAddress, getCategory, getPrice, postOrder, getCoupon, getOrder, getVideoInfo, getVideoPlay, getVideoSub } from '../../services/newOder';
import { DeleteOutlined, FormOutlined, PlusCircleOutlined, PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { getDepartment_Data, getRequest, setBalance } from '../../config/storage';
import { getMeasureDataList } from '../../services/measureData';
import { getExcel } from '../../services/orderRenter';
import { getFinancebase } from '../../services/finance';
import { getShopVideos } from '../../services/storeManagement';
import RemarkModal from './remarkModal';
import { ossUpload, } from '../../utils/ossUpload.js';
import convert from '../../utils/convert';
import styles from './index.module.css';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
moment.locale('zh-cn');
import { connect } from 'dva';

const serviceDatas = {
  sort: 0,
  id: 0,
  order: 0,
  room: '窗帘1',
  width: '1',
  width_remark: '',
  height: '0',
  height_remark: '',
  prop1: '',
  prop2: '1',
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
  prop7: '',
  prop8: '',
  prop9: '',
  prop10: '',
  prop11: '',
  prop12: '',
  prop13: '',
  prop14: '',
  prop15: '',
  state: '已确认',
  pic: '',
  remark: '',
}

const postShopData = {
  id: 0,
  category: "",
  type: "",
  service: "",
  name: "",
  order_way: "",
  phone: "",
  province: "",
  city: "",
  district: "",
  address: "",
  price_app: 0,
  add_app: 0,
  add_remark: "",
  shop_remark: "",
  urge_contact: "否",
  bonus_order: "否",
  urge_order: "否",
  serviceDatas: [],
  serviceItems: [],
}

const customerDataInit = {
  receiver: '',
  phone: '',
  province: '北京市',
  city: '北京市',
  district: '朝阳区',
  address: '',
  hasWorker: false,
}
const { Option } = Select;
const videoModel = {
  measure: {
    pics: [],
    vod: [],
  },
  install: {
    pics: [],
    vod: [],
  },
};
const roomType = ['主卧', '次卧', '客厅', '厨房', '客餐厅', '阳台', '书房', '卫生间', '儿童房', '餐厅', '保姆房', '商务年会'];
const fastRemarks1 = ['和客户确认是否满足测量条件再上门!', '现场禁止收取任何费用如果有请和平台联系!', '现场请和客户确认好内装/外装方案 避免二次上门!', '测量务必要求客户签字落实测量方案!'];
const fastRemarks2 = [
  '注意轨道需交叉安装！任何问题麻烦现场直接反馈墨墨！',
  '麻烦师傅带切割机，预防长了可以截断，谢谢!',
  '安装现场如果有任何尺寸问题 请第一时间反馈平台处理!',
  '现场禁止收取任何费用，如有其它费用请第一时间联系平台处理!',
  '请和业主确认货物全部到货才可以上门安装!',
  '请在安装好之后对产品做一个好评，谢谢哦',
  '麻烦师傅带上螺丝，防止现场没有螺丝!',
  '严格按照清单安装 不提供清单之外的安装服务!'
];
// const [form] = Form.useForm()
// const [form] = React.createRef()

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: true,
      customerData: customerDataInit,

      visible: false,
      modalIndex: 0,
      roomModalValue: '窗帘1',

      postSpin: false,
      spinningTable: false,
      dateType: false,
      requestType: false,
      couponModal: false,
      orderInof: [],
      couponData: [],//优惠券
      types: '',
      serviceTypes: '测量',
      measure: '普通测量',
      postData: [{ ...serviceDatas }],
      data: [],
      datas: {},
      other: [],
      serviceType: ['测量', '安装'],
      newOrderData: {},
      appointDate: '',
      appointDateTime: '',
      orderTypeID: 0,
      selectedRowKeys: [],
      measureID: 0,
      orderPrice: [0],
      orderPrice_oms: [0],
      serviceItems: [],
      specialServiceItems: [],
      orderRemark: '',
      tbRemark: '',
      specialPrice: 0,
      specialRemark: '',

      pics: [],
      videoPlay: [],
      installVideos: {
        pics: [],
        vod: [],
      },
      measureVideos: {
        pics: [],
        vod: [],
      },
      videoList: { ...videoModel },//原模板

      grid: [],
      groupType: [],
      // detail: [],
      detailType: [],
      detailID: [],
      // detailAdd: [],
      optionsRadio: [],

      newOrderData: {},
      hasWorker: false,
      price: 0,
      minDistance: 0,
      noneWorker: false,
      columns: [],
      defaultPrice: 0,

      otherCheckbox: [],
      otherCheckboxPrice: [],
      otherCheckboxPrice_oms: [],
      serviceItemG: [],

      transferShow: false,
      transferList: [],
      fastRemark: [],
    }
    this.bonus_type = getDepartment_Data().bonus_type;
    // this.bonus_type = '不参与';
    this.baseUrl = getDepartment_Data().service;
    this.service = getDepartment_Data().service_type === '' ? [] : JSON.parse(getDepartment_Data().service_type);
    this.show_book = getDepartment_Data().show_book;
    this.shopId = getDepartment_Data().shopId;
    this.fix_price = getDepartment_Data().fix_price;
    this.remarkModal = React.createRef();
    this.couponColumns = [
      {
        title: '名称',
        align: 'center',
        dataIndex: 'name',
        width: 150,
      },
      {
        title: '类型',
        align: 'center',
        dataIndex: 'name',
        width: 150,
        render: (text, record, index) => {
          return (<div>{record.category + '-' + record.service}</div>);
        }
      },
      {
        title: '优惠金额',
        align: 'center',
        dataIndex: 'price',
        width: 150,
      },
      {
        title: '失效时间',
        align: 'center',
        dataIndex: 'due_time',
        width: 150,
        sorter: (a, b) => new Date(a.due_time).getTime() - new Date(b.due_time).getTime(),
        defaultSortOrder: 'ascend',
        render: (text, record, index) => {
          return (<div>{moment(record.due_time).format('YYYY/MM/DD-HH:mm')}</div>);
        }
      },
    ];
  }
  form = React.createRef()
  componentDidMount() {
    this.setState({
      orderInof: this.service,
      types: this.service[0],
      newOrderData: this.props.newOrderData,
      serviceType: ['测量', '安装']
    });
    if (JSON.stringify(this.props.newOrderData) !== '{}') {
      this.setState({
        // serviceType: ['安装'],
        serviceTypes: '安装',
      })
      // this.getNewOrderDatas(this.props.newOrderData);
      this.getCategoryData(this.props.newOrderData.category)
      this.getColumns(this.props.newOrderData.category)
    } else {
      this.getCategoryData(this.service[0])
      this.getColumns(this.service[0])
    }
    this.getVideoTemplate();
  }
  componentWillUnmount() {
    this.setState = () => false;
    const { dispatch } = this.props;
    dispatch({
      type: 'orderRenter/delNewOrderData',
      payload: {},
    });
  }

  render() {
    const {
      spinning, spinningTable, customerData, couponModal,
      orderInof, types, serviceTypes, measure,
      postData, dateType, other,
      data, postSpin, couponData,
      visible, roomModalValue, selectedRowKeys,
      hasWorker, price, minDistance, noneWorker,
      measureID, columns,
      transferList, transferShow,
      fastRemark,
      pics, videoPlay, installVideos, measureVideos,
    } = this.state;
    const rowSelection = {
      columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'radio',
      onChange: this.onSelectCoupon,
    };
    const transferColumns = [
      {
        title: '客户姓名',
        align: 'center',
        dataIndex: 'name',
        width: 150,
        className: 'padding0',
      },
      {
        title: '手机',
        align: 'center',
        dataIndex: 'phone',
        width: 150,
        className: 'padding0',
      },
      {
        title: '地址',
        align: 'center',
        dataIndex: 'address',
        width: 550,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div>{record.province + record.city + record.district + record.address}</div>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'category',
        width: 200,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='col w100'>
              <div onClick={this.onExcelData.bind(this, record.id)} className={`${styles['operate_btn']} margin-b5`}>查看测量数据</div>
              <div onClick={this.transferOrder.bind(this, record.id)} className={styles['operate_btn']}>一键转单</div>
            </div>
          );
        }
      },

    ];
    return (
      <Spin tip="数据加载中..." size="large" spinning={spinning}>
        <div className={'row-l vertical-lt'}>
          <div className={`${styles['div-left']} col margin-r10`}>
            <div className={'back-color2 w100 padding20 row-sb vertical-lt'}>
              <div className={`${styles['top-style']}`}>
                <div className={styles['top-left-title']}>① 填写客户信息</div>
                <div className={`${styles['top-left-Input']} row-l vertical-lt`}>
                  <div className='margin-r10'>快速识别:</div>
                  <div style={{ width: 600 }}>
                    <Input.TextArea
                      rows={3}
                      placeholder="可将客户信息 (姓名、手机、地址) 黏贴到这里；分机号请填写到地址或备注"
                      name='analysisAddress'
                      value={this.state.analysisAddress}
                      onChange={this.onAnalysisAddressValue.bind(this)}
                    />
                    <div className={styles['top-left-tips']}>
                      温馨提示：为避免信息识别不正确，请在输入地址信息时避免换行操作；如果有换行情况 ，建议您手动删除换行即可正常识别。
                    </div>
                    {/* <div className={styles['top-left-tips']}>温馨提示: 客户姓名、电话、地址复制到输入区域内, 点击自动识别后, 在右侧核对; 或直接在右侧手动录入相关客户信息。</div> */}
                    <div className='row-l'>
                      <div className={styles['top-left-button']} onClick={this.onAnalysisAddress.bind(this)}>自动识别客户信息</div>
                      <div className='col'>
                        {hasWorker && price === 0 && <div className='color6 fz20 margin-l20'>该地区师傅资源充足, 请放心下单</div>}
                        {hasWorker && price > 0 && <div className='color2 margin-l20'> 距离最近的师傅相差{minDistance}公里，需要收取远程费{price}元 </div>}
                        {noneWorker && <div className='color2 margin-l20'> 该地区暂无师傅，请联系客服开发</div>}
                      </div>
                    </div>
                  </div>
                </div>
                {
                  transferShow ?
                    <div className='col back-color2 w100 margin-t10 vertical-lt' >
                      <div>关联测量订单</div>
                      <Table
                        dataSource={transferList}
                        columns={transferColumns}
                        size={'small'}
                        rowKey={record => record.id}
                        bordered
                        pagination={{ defaultPageSize: 1 }}
                      />
                    </div>
                    : <div></div>
                }
              </div>
              <div className={styles['split-line']}></div>
              <div className={`${styles['top-style']} margin-l20 relative`}>
                <div className={`${styles['top-right-empty']} ${styles['empty-w-style']} margin-b20`} onClick={this.onDelForm.bind(this)}> <DeleteOutlined /> 清空客户信息</div>
                {/* <Form form={form}> */}
                <div onClick={this.onSearchConnect} className={styles['search_btn']}>搜索订单</div>
                <Form ref={this.form}>
                  <Form.Item
                    name='receiver'
                    label='客户姓名'
                    initialValue={customerData.receiver}
                    rules={[{
                      required: true,
                      message: '请输入客户姓名',
                    }]}
                    getValueFromEvent={(e) => {
                      const { value } = e.target;
                      return value.replace(/[^\w\u4e00-\u9fa5]/g, '');
                    }}
                  >
                    <Input placeholder='请输入客户名字' onBlur={this.onCusromerChange.bind(this, 'receiver')} />
                  </Form.Item>
                  <Form.Item
                    name='phone'
                    label='客户手机'
                    initialValue={customerData.phone}
                    rules={[{
                      required: true,
                      message: '请输入客户手机',
                    }]}
                  >
                    <Input placeholder='请输入客户手机，分机号请填写到地址或备注' onBlur={this.onCusromerChange.bind(this, 'phone')} />
                  </Form.Item>
                  <Form.Item
                    label='客户地址'
                  >
                    <Regin value={[customerData.province, customerData.city, customerData.district]} onChange={this.onReginChange} />
                  </Form.Item>
                  <Form.Item
                    name='address'
                    label='详细地址'
                    initialValue={customerData.address}
                  >
                    <Input.TextArea autoComplete="off" rows={4} placeholder="显示自动识别详细地址（您可也手动填入）" onBlur={this.onCusromerChange.bind(this, 'address')} />
                  </Form.Item>

                </Form>
              </div>
            </div>
            <div className='col back-color2 w100 margin-t10 padding20 vertical-lt'>
              <div className='row-sb w100'>
                <div className={styles['top-left-title']}>② 填写订单信息</div>
                <div className={`${styles['top-right-empty']} margin-b20`} onClick={this.onClickEmptyOrder.bind(this)}> <DeleteOutlined /> 清空订单信息</div>
              </div>
              <div className='row-l margin-t10'>
                <span className='margin-r10'>选择品类:</span>
                <div className='row-l'>
                  {
                    orderInof.map((item, index) => {
                      return (
                        <div className={`${styles['choiceType_div']} ${types === item && styles['choiceType_Color']}`} onClick={this.onGetOrderType.bind(this, item)} key={index}>
                          {item}
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className='row-l margin-t10 margin-b20'>
                <span className='margin-r10'>服务类型:</span>
                <div className='row-l'>
                  {
                    this.state.serviceType.map((item, index) => {
                      return (
                        <div className={`${styles['choiceType_div']} ${serviceTypes === item && styles['choiceType_Color']}`} onClick={this.getData.bind(this, item, data)} key={index}>
                          {item}
                        </div>
                      )
                    })
                  }
                  <a href='https://ljkj-mmgj-temp.oss-cn-hangzhou.aliyuncs.com/BaseData/2024墨墨管家窗帘安装价格表.xlsx'
                    className={`${styles['choiceType_div']} ${styles['choiceType_Color']} margin-l40 back-color7`}
                  >
                    价目表
                  </a>
                  {/* https://ljkj-mmgj-temp.oss-cn-hangzhou.aliyuncs.com/BaseData/2024墨墨管家窗帘安装价格表.xlsx */}
                </div>
              </div>
              <div className={styles['measure-grid']}>
                {
                  measureID !== 0 && serviceTypes === '测量' && <div className={'row-l margin-t10 margin-b20'}>
                    <span className='margin-r10'>测量类型:</span>
                    <Radio.Group value={measure} onChange={this.onMeasureChange}>
                      {
                        this.state.datas.grid.length !== 0 && this.state.datas.grid[0].detail.map((item, index) => {
                          return (
                            <Radio value={item.name} key={index}>{item.name}</Radio>
                          )
                        })
                      }
                    </Radio.Group>
                  </div>
                }
                {other.length > 0 && <div className={`${styles['measure-grid-radio']} row-l padding-tb20`}>
                  {
                    other.map((item, index) => {
                      return (
                        <div className={`row-l margin-r20 ${item.name === '好评返现' && ['不固定金额', '不参与'].includes(this.bonus_type) ? 'hidden' : ''}`} key={index}>
                          <Checkbox disabled={item.name === '好评返现' && ['固定金额', '不固定金额'].includes(this.bonus_type)} onChange={this.onOtherChange.bind(this, item.id, item.name, index)} checked={this.state.otherCheckbox[index]}>{item.name}</Checkbox>
                          {item.name === '订单加急' && <div className='color5'>（勾选加急后, 立即为您安排相关师傅对接；收取加急费用<span className='color2'>30</span>元）</div>}
                        </div>
                      )
                    })

                  }
                </div>}
                {
                  this.show_book !== '否' &&
                  <div className={`${styles['measure-grid-radio']} row-l padding-tb20`}>
                    <div className='row-l'>
                      <Checkbox checked={dateType} onChange={this.onDateChange}>指定预约时间</Checkbox>
                      <div className={styles['dateType']}>
                        {
                          dateType ? (
                            <div className='row-l'>
                              <DatePicker locale={locale} className={styles['DatePicker']} onChange={this.getDateChange} />
                              <TimePicker locale={locale} className={styles['DatePicker']} onChange={this.onTimeChange} />
                            </div>
                          ) : (
                            <div className={styles['date']}>勾选后可预约时间</div>
                          )
                        }
                      </div>
                    </div>
                  </div>}
                {
                  serviceTypes === '安装' && <div className={`${styles['measure-grid-radio']}`}></div>
                }
              </div>
              {
                JSON.stringify(this.state.datas) !== '{}' && serviceTypes === '安装' && (types === '其他' || types === '地板') &&
                <div className={styles['measure-grid']}>
                  <div className={'row-l margin-t10 margin-b20'}>
                    <Radio.Group value={measure} onChange={this.onMeasureChange}>
                      {
                        this.state.datas.grid.length !== 0 && this.state.datas.grid[0].detail.map((item, index) => {
                          return (
                            <Radio value={item.name} key={index}>{item.name}</Radio>
                          )
                        })
                      }
                    </Radio.Group>
                  </div>
                </div>
              }
              {serviceTypes === '安装' && types !== '其他' && types !== '地板' && <div className='w100 margin-t20'>
                <div className='margin-r10'>安装信息:</div>
                <Spin tip="数据加载中..." spinning={spinningTable}>
                  <Table
                    columns={columns}
                    dataSource={postData}
                    size={'small'}
                    rowKey={record => record.sort + Math.random()}
                    bordered
                    pagination={{ defaultPageSize: 50 }}
                  />
                </Spin>
                <div className={`${styles['top-right-empty']} margin-b20`} onClick={this.onAddData}><PlusCircleOutlined /> 新增产品</div>
              </div>}
              <div className={styles['tips']}>{
                serviceTypes === '测量' ?
                  '(温馨提示：平台提供尺寸不设计窗幔尺寸，如果改客户需要做窗幔请务必和墨墨管家沟通，确认窗幔尺寸无误谢谢！)'
                  :
                  ' (温馨提示：平台将严格按照您填写的窗户信息进行安装，请确保您所填写的窗户信息无误！)'
              }</div>
              <div className='col-t w100 vertical-lt'>
                <div className={`${styles['remarks-div-Box']} row-l w100 vertical-lt`}>
                  <div className='w50 col-t vertical-lt margin-t20'>
                    <div className='row-l  vertical-lt'>
                      <div className={'margin-r10 w90'}>订单备注:</div>
                      <Input.TextArea value={this.state.orderRemark} rows={4} placeholder="可添加相关备注" style={{ width: 600 }} onChange={this.onShopRemark.bind(this, '')} />
                    </div>
                    <div className='row-l  vertical-lt margin-t10'>
                      <div className={'margin-r10 w90'}>淘宝订单号:</div>
                      <Input.TextArea value={this.state.tbRemark} rows={2} placeholder="可添加淘宝订单号" style={{ width: 600 }} onChange={this.onTbRemark} />
                    </div>
                  </div>
                  <div className={'col w50 margin-t20'}>
                    <div className='row-sb w100'>
                      <div>快速备注<span className='color5'>(直接点击即可在左侧添加备注)</span></div>
                      <div className='color5 cursor-pointer' onClick={this.EditRemark}><FormOutlined className='margin-r10' />编辑</div>
                    </div>
                    {
                      serviceTypes === '测量' ? (
                        <div className={`${styles['overflow-y']} w100`}>
                          {
                            fastRemark.map((item, index) => {
                              return (<div className={styles['div-remarks-style']} onClick={this.onShopRemark.bind(this, item.remark)} key={'f' + index}>{item.remark}</div>)
                            })
                          }
                          {
                            fastRemarks1.map((item, index) => {
                              return (<div className={styles['div-remarks-style']} onClick={this.onShopRemark.bind(this, item)} key={index}>{item}</div>)
                            })
                          }
                        </div>
                      ) : (
                        <div className={`${styles['overflow-y']} w100`}>
                          {
                            fastRemark.map((item, index) => {
                              return (<div className={styles['div-remarks-style']} onClick={this.onShopRemark.bind(this, item.remark)} key={'f' + index}>{item.remark}</div>)
                            })
                          }
                          {
                            fastRemarks2.map((item, index) => {
                              return (<div className={styles['div-remarks-style']} onClick={this.onShopRemark.bind(this, item)} key={index}>{item}</div>)
                            })
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className='row-l w100 margin-t10'>
                  <div className={'margin-r10 w90'}>图片备注：</div>
                  <div className='col-t vertical-lt w100'>
                    <div className='row-w w900'>
                      {
                        serviceTypes === '测量' ?
                          measureVideos.pics.map((item, index) => {
                            return (
                              <div className={`${styles['video']} ${item.selected ? styles['video-select'] : ''} none-select`}
                                key={index} onClick={this.selectVideo.bind(this, index, 'pics', serviceTypes)}>
                                {item.name}
                              </div>
                            );
                          })
                          :
                          installVideos.pics.map((item, index) => {
                            return (
                              <div className={`${styles['video']} ${item.selected ? styles['video-select'] : ''} none-select`}
                                key={index} onClick={this.selectVideo.bind(this, index, 'pics', serviceTypes)}>
                                {item.name}
                              </div>
                            );
                          })
                      }
                    </div>
                    <div className='row-w w100'>
                      <Upload
                        type='file'
                        beforeUpload={this.onAddPic}
                        fileList={[]}
                        accept='image/*'
                        multiple={true}
                      >
                        <div className={`row cursor-pointer ${styles['pic-div']}`}>
                          <div className="row h100">
                            <PlusOutlined />
                            <span>
                              新增图片
                            </span>
                          </div>
                        </div>
                      </Upload>
                      {
                        pics.map((item, index) => {
                          return (
                            <div key={index} className={`${styles['content']}`}>
                              <div className={`row ${styles['pic-icon']}`}
                                onClick={this.delSignPic.bind(this, index)}
                              >
                                <DeleteOutlined />
                              </div>
                              <img
                                alt="暂无图片"
                                className={`${styles['pic']}`}
                                src={`${this.baseUrl}/upload/${item}`}
                              />
                              <div className={`row ${styles['content-div']}`}>
                                <EyeOutlined
                                  className={`${styles['pic-icon-E']}`}
                                  onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                                  theme="outlined"
                                  title="预览"
                                />
                              </div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className='row-l vertical-lt w100 margin-t10'>
                  <div className={'margin-r10 w90'}>视频备注：</div>
                  <div className='col-t vertical-lt w100'>
                    <div className='row-w w900'>
                      {
                        serviceTypes === '测量' ?
                          measureVideos.vod.map((item, index) => {
                            return (
                              <div className={`${styles['video']} ${item.selected ? styles['video-select'] : ''} none-select`}
                                key={index} onClick={this.selectVideo.bind(this, index, 'vod', serviceTypes)}>
                                {item.name}
                              </div>
                            );
                          })
                          :
                          installVideos.vod.map((item, index) => {
                            return (
                              <div className={`${styles['video']} ${item.selected ? styles['video-select'] : ''} none-select`}
                                key={index} onClick={this.selectVideo.bind(this, index, 'vod', serviceTypes)}>
                                {item.name}
                              </div>
                            );
                          })
                      }
                    </div>
                    <div className='row-l vertical-lt w100'>
                      <Upload
                        type='file'
                        beforeUpload={this.uploadVideo}
                        customRequest={() => { }}
                        fileList={[]}
                        multiple={false}
                        maxCount={1}
                      >
                        <div className={`row cursor-pointer ${styles['pic-div']}`}>
                          <div className="row h100">
                            <PlusOutlined />
                            <span>
                              新增视频
                            </span>
                          </div>
                        </div>
                      </Upload>
                      <div className='row-w w100'>
                        {
                          videoPlay.map((item, index) => {
                            return (
                              <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                <div className={`${styles['content-video']}`}>
                                  <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                </div>
                                <Popconfirm
                                  title="您确定要删除该视频吗?"
                                  onConfirm={this.delVideo.bind(this, index)}
                                  okText="确认"
                                  cancelText="取消"
                                >
                                  <Button type='danger' className='w100 margin-t5'>删除视频</Button>
                                </Popconfirm>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col back-color2 w100 margin-t10 margin-b105 padding20 vertical-lt'>
              <div className='row-sb w100'>
                <div className={styles['top-left-title']}>③ 确认订单费用</div>
              </div>
              <div className='row-l margin-t10'>
                <span className={styles['title-order-cost']}>特殊费用:</span>
                <InputNumber
                  // defaultValue={0}
                  value={this.state.specialPrice}
                  addonAfter="元"
                  style={{ width: 400 }}
                  onChange={this.onSpecialChange.bind(this)}
                  min={0}
                />
                <div className='color5 margin-l10'> (添加后,将被加至总费用金额中)</div>
              </div>
              <div className='row-l margin-t10'>
                <span className={styles['title-order-cost']}>特殊费用备注:</span>
                <Input.TextArea value={this.state.specialRemark} rows={4} placeholder="填写相关特殊费用备注信息" style={{ width: 1200 }} onChange={this.onAppRemark.bind(this)} />
              </div>
            </div>
            <div className={`${styles['priceStyles']} row back-color2 w100 padding20 vertical-lt`}>
              <div className='col'>
                <div className='row'>
                  <div className='row-l'>
                    <span className={styles['title-order-cost']}>特殊金额:</span>
                    <div>
                      <span className='color2 fz20'>
                        {this.state.specialPrice}
                      </span>元
                    </div>
                  </div>
                  <div className='row-l'>
                    <span className={styles['title-order-cost']}>商品金额:</span>
                    <div>
                      <span className='color2 fz20'>
                        {this.getAllPrices() - this.getCouponPrice()}
                      </span>元
                    </div>
                  </div>
                  <div className='row-l'>
                    <span className={styles['title-order-cost']}>应付金额:</span>
                    <div>
                      <span className='color2 fz20'>
                        {this.getAllPrices() - this.getCouponPrice() + this.state.specialPrice}
                      </span>元
                    </div>
                  </div>
                </div>
                <div className='row-l'>
                  <Spin spinning={postSpin}>
                    <div className={`${styles['place-order-style']} margin-r10`}
                      onClick={this.judgeCoupon}
                    // onClick={this.onPostClick.bind(this, false)}
                    >
                      立即下单
                    </div>
                  </Spin>
                  <Spin spinning={postSpin}>
                    <div className={styles['preservation-style']}
                      onClick={this.onPostClick.bind(this, true)}
                    >
                      保存草稿箱
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={'修改窗帘名称'}
          visible={visible}
          width={700}
          onCancel={this.onCancel}
          onOk={this.onOk}
          okText='确定'
          cancelText='取消'
        >
          <div>
            <Input className='margin-b10' value={roomModalValue} onChange={this.onModalValueChange} />
            <div className='row-w'>
              {
                roomType.map((item, index) => {
                  return (
                    <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                  )
                })
              }
            </div>
          </div>
        </Modal>
        <Modal
          title={'选择优惠券'}
          visible={couponModal}
          width={700}
          onCancel={this.onCancelCoupon}
          okText='确定使用'
          cancelText='不使用优惠券'
          footer={
            <div className='w100 row-r'>
              <div className={`${selectedRowKeys.length > 0 ? '' : 'color2'} fz18 margin-r20 bold`}>{selectedRowKeys.length > 0 ? '已选择优惠券' : '未选择优惠券'}</div>
              <Button onClick={this.onCancelCoupon} type='ghost'
                className='w150 h34 border-r5 fz16'
              >
                取消
              </Button>
              <Button onClick={this.onPostClick.bind(this, false)} type='primary'
                className='w150 h34 border-r5 fz16'
              >
                确定
              </Button>
            </div>
          }
        >
          {
            selectedRowKeys.length > 0 &&
            <div className=''>已选择优惠券</div>
          }
          <Table
            rowSelection={rowSelection}
            dataSource={couponData}
            columns={this.couponColumns}
            size={'small'}
            rowKey={record => record.id}
            bordered
          />
        </Modal>
        <RemarkModal
          ref={this.remarkModal}
          updataRemark={this.updataRemark}
        />
      </Spin>
    );
  }
  getVideoTemplate = async () => {
    let res = await getShopVideos(this.shopId);
    if (res.status === 200) {
      let videoList = res.data ? JSON.parse(res.data) : { ...videoModel };
      let installVideos = {
        pics: [],
        vod: [],
      };
      let measureVideos = {
        pics: [],
        vod: [],
      };
      if (videoList.install) {
        installVideos = videoList.install;
      }
      if (videoList.measure) {
        measureVideos = videoList.measure;
      }
      this.setState({
        installVideos, measureVideos,
        videoList,
      });
    }
  }
  selectVideo = (index, category, type) => {
    let measureVideos = _.cloneDeep(this.state.measureVideos);
    let installVideos = _.cloneDeep(this.state.installVideos);
    if (type === '测量') {
      measureVideos[category][index].selected = !measureVideos[category][index].selected;
    } else {
      installVideos[category][index].selected = !installVideos[category][index].selected;
    }
    this.setState({
      installVideos, measureVideos,
    });
  }
  //图片备注
  onAddPic = async (file, fileList) => {
    let pics = _.cloneDeep(this.state.pics)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = pics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      pics[leng + i] = name;
    }
    this.setState({
      pics
    })
  }
  delSignPic = (e) => {
    let pics = _.cloneDeep(this.state.pics)
    pics.splice(e, 1);
    this.setState({
      pics,
    });
  }



  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  //上传视频
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let videoPlay = [...this.state.videoPlay]
      videoPlay.push({
        name: '新增视频',
        videoId: e,
        videoUrl: res.data.playURL,
      });
      this.setState({
        videoPlay: videoPlay
      })
    }
  }
  delVideo = (index) => {
    let videoPlay = [...this.state.videoPlay]
    videoPlay.splice(index, 1)
    this.setState({
      videoPlay: videoPlay
    })
  }

  getUuid = () => {
    var s = [];
    var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = "4"
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
    s[8] = s[13] = s[18] = s[23] = "-"
    let uuid = s.join("")
    return uuid
  }
  getNewOrderDatas = (newOrderData, data) => {
    let customerData = { ...customerDataInit };
    customerData.receiver = newOrderData.name;
    customerData.city = newOrderData.city;
    customerData.district = newOrderData.district;
    customerData.address = newOrderData.address;
    customerData.province = newOrderData.province;
    customerData.phone = newOrderData.phone;
    newOrderData.serviceItems = [];
    // let serviceData = [{ ...newOrderData.serviceDatas }];
    let serviceData = [{ ...serviceDatas }];
    let orderTypeID = 0;
    let dataIndex = 0;
    data.map((ditem, dindex) => {
      if (ditem.type.includes('安装')) {
        dataIndex = dindex
      }
    })
    let otherCheckbox = [];
    let otherCheckboxPrice = [];
    let otherCheckboxPrice_oms = [];
    data[dataIndex].other.map(item => {
      otherCheckbox.push(false);
      otherCheckboxPrice.push(0);
      otherCheckboxPrice_oms.push(0);
    })
    this.setState({
      newOrderData: newOrderData,
      data: data,
      customerData: customerData,
      postData: serviceData,
      types: newOrderData.category,
      serviceTypes: '安装',

      datas: data[dataIndex],
      other: data[dataIndex].other,

      grid: data[dataIndex].grid,
      groupType: [data[dataIndex].grid[0].group],
      detailType: [data[dataIndex].grid[0].detail[0].name],
      detailID: [data[dataIndex].grid[0].detail[0].id],
      defaultPrice: data[dataIndex].price,

      specialPrice: newOrderData.add_app,
      specialRemark: newOrderData.add_remark,
      orderRemark: newOrderData.shop_remark,
      serviceItems: newOrderData.serviceItems,

      otherCheckbox: otherCheckbox,
      otherCheckboxPrice: otherCheckboxPrice,
      otherCheckboxPrice_oms: otherCheckboxPrice_oms,
    })
    // this.getOptionsRadio([...serviceData], data[dataIndex].grid[0].detail[0].add, 0);
    this.transferOrder(newOrderData.id);
    this.form.current.setFieldsValue({ ...customerData });
  }
  getProp2Price = (prop2, index) => {
    let { orderPrice, postData, types } = this.state;
    let price = 0;
    let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
    let grapplePrice = this.getGrapplePrice(index, multiple);
    price = orderPrice[index] * Number(prop2) * multiple - grapplePrice.price_app * Number(prop2);
    return price;
  }
  getGrapplePrice = (index, multiple) => {
    let { optionsRadio, serviceItemG } = this.state;
    if (multiple === 1) {
      return { price_app: 0, price_oms: 0 };
    }
    if (!optionsRadio[index]['墙钩']) {
      return { price_app: 0, price_oms: 0 };
    }
    if (optionsRadio[index]['墙钩'].name === '无') {
      return { price_app: 0, price_oms: 0 };
    }
    let newOptionsRadio = optionsRadio[index];
    let newServiceItemG = serviceItemG[index];
    let data = newServiceItemG.filter(r => r.service === newOptionsRadio['墙钩'].id)[0];
    if (!data) { return { price_app: 0, price_oms: 0 }; }
    let price_app = data.price_app;
    let price_oms = data.price_oms;
    return {
      price_app: price_app * multiple - price_app,
      price_oms: price_oms * multiple - price_oms,
    }
  }
  getTypeProp2 = (type, width) => {
    let quantity = 0;
    let widths = Number(width);
    let prop2Multiple = 0;
    if (type === '布帘') {
      quantity = 3;
      if (widths > 1) {
        widths = widths - 1;
      }
    } else if (type === '成品帘') {
      quantity = 2.5;
    } else if (type === '电动成品帘') {
      quantity = 3;
    } else {
      quantity = 5;
    }
    prop2Multiple = Math.floor(widths / quantity) + 1;
    if (this.fix_price === '是') {
      return 1;
    }
    return prop2Multiple;
  }
  getTypeProp = (type, width) => {
    let quantity = 0;
    let widths = Number(width);
    let prop2Multiple = 0;
    if (type === '布帘') {
      quantity = 3;
      if (widths > 1) {
        widths = widths - 1;
      }
    } else if (type === '成品帘') {
      quantity = 2.5;
    } else if (type === '电动成品帘') {
      quantity = 3;
    } else {
      quantity = 5;
    }
    prop2Multiple = Math.floor(widths / quantity) + 1;
    return prop2Multiple;
  }
  getTypePropOms = (type, width, category) => {
    let prop2Multiple = 1;
    let widths = Number(width);
    if (type === '布帘') {
      if (category.includes('梦幻帘')) {
        prop2Multiple = 1;
      } else {
        if (widths > 4) {
          prop2Multiple = 2;
        }
      }
    }
    return prop2Multiple;
  }
  //③ 确认订单费用
  getCouponPrice = () => {
    let { couponData, selectedRowKeys } = this.state;
    let couponID = 0;
    let price = 0;
    if (couponData.length === 0 || selectedRowKeys.length === 0) {
      return 0;
    } else {
      couponID = selectedRowKeys[0]
      if (couponData.filter(r => r.id === couponID)[0]) {
        price = couponData.filter(r => r.id === couponID)[0].price;
      }
      return price;
    }

  }
  //选择优惠券
  onCouponID = (e) => {
    this.setState({
      couponID: e
    })
  }
  //获取优惠券
  getDiscount = async () => {
    let { serviceTypes, types } = this.state;
    let res = await getCoupon(types, serviceTypes);
    if (res.status !== 200) {
      message.error(res.data);
      return
    }
    // let couponData = res.data;
    // if (couponData.length > 0) {
    //   couponData.unshift({
    //     id: 0,
    //     name: '优惠券',
    //     pic: '',
    //     service: this.state.serviceTypes,
    //     category: this.state.types,
    //     price: 0,
    //   })
    // }
    this.setState({
      couponData: res.data,
      // couponID: res.data.length === 0 ? 0 : res.data[0].id
    })
  }
  //获取金额
  getAllPrices = () => {
    const { orderPrice, specialPrice, postData, serviceTypes, defaultPrice, otherCheckboxPrice } = this.state;
    let orderPriceNUM = 0;
    orderPrice.forEach((item, index) => {
      if (serviceTypes === '安装') {
        let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
        let grapplePrice = this.getGrapplePrice(index, multiple);
        orderPriceNUM += item * Number(postData[index].prop2) * multiple - grapplePrice.price_app * Number(postData[index].prop2);
      } else {
        orderPriceNUM += item
      }
    });
    let price_app = 0;
    otherCheckboxPrice.map(item => {
      price_app += item
    })
    let price = orderPriceNUM > defaultPrice ? orderPriceNUM : defaultPrice;
    return price + price_app
  }
  getAllPricesOms = () => {
    const { orderPrice_oms, postData, otherCheckboxPrice_oms } = this.state;
    let orderPriceNUM = 0;
    orderPrice_oms.forEach((item, index) => {
      let prop3 = postData[index].prop3 ? JSON.parse(postData[index].prop3).name : '';
      let prop9 = postData[index].prop9 ? JSON.parse(postData[index].prop9).name : '';
      let multiple = this.getTypePropOms(postData[index].prop1, postData[index].width, prop3);
      let grapplePrice = this.getGrapplePrice(index, multiple);
      orderPriceNUM += item * Number(postData[index].prop2) * multiple - grapplePrice.price_oms * Number(postData[index].prop2);
    });
    let price_oms = 0;
    otherCheckboxPrice_oms.map(item => {
      price_oms += item
    })
    let price = orderPriceNUM + price_oms;
    return price;
  }
  //特殊费用
  onSpecialChange = (e) => {
    this.setState({
      specialPrice: e
    })
  }
  //特殊备注
  onAppRemark = (e) => {
    this.setState({
      specialRemark: e.target.value
    })
  }
  //下单
  onPostClick = async (preoder) => {
    this.setState({
      couponModal: false,
    })
    if (this.state.datas.grid.length === 0) {
      return;
    }
    let {
      customerData, specialServiceItems, serviceItems, postData, appointDate,
      types, dateType, serviceTypes, specialRemark, orderRemark, specialPrice,
      orderPrice, orderPrice_oms, appointDateTime, selectedRowKeys,
      tbRemark, pics, videoPlay, installVideos, measureVideos,
    } = this.state;
    if (customerData.receiver === '') {
      message.error('请填写客户名称');
      return;
    }
    if (customerData.phone === '') {
      message.error('请填写客户手机号');
      return;
    }
    if (customerData.phone.length !== 11) {
      message.error('手机号不正确');
      return;
    }
    if (customerData.address === '') {
      message.error('请填写客户详细地址');
      return;
    }
    if (dateType && (appointDate === '' || appointDateTime === '')) {
      message.warn('请指定预约时间')
      return;
    }
    this.setState({
      postSpin: true
    })
    let data;
    let methed = '新建';
    let couponID = 0;
    if (selectedRowKeys.length > 0) {
      couponID = selectedRowKeys[0];
    }
    if (JSON.stringify(this.props.newOrderData) !== '{}') {
      // data = { ...this.state.newOrderData };
      // delete data.serviceAfterSales
      // delete data.serviceFollows
      data = { ...postShopData };
      methed = '转单'
    } else {
      data = { ...postShopData };
    }
    data.id = 0;
    data.name = customerData.receiver;
    // if (tbRemark) {
    //   data.name += `|${tbRemark}`;
    // }
    data.phone = customerData.phone;
    data.address = customerData.address;
    data.city = customerData.city;
    data.district = customerData.district;
    data.province = customerData.province;
    data.category = types;
    data.price_app = this.getAllPrices();
    data.price_oms = this.getAllPricesOms();
    let addition = [];
    postData.forEach((item, index) => {
      let prop3 = item.prop3 ? JSON.parse(item.prop3).name : '';
      let prop9 = item.prop9 ? JSON.parse(item.prop9).name : '';
      let multiple = this.getTypeProp2(item.prop1, item.width);
      let multiple2 = this.getTypePropOms(item.prop1, item.width, prop3);
      if (types === '窗帘') {
        item.prop10 = orderPrice[index] * Number(item.prop2) * multiple + ''
        item.prop11 = orderPrice_oms[index] * Number(item.prop2) * multiple2 + ''
      } else if (types === '墙布墙纸' || types === '晾衣架') {
        item.prop8 = orderPrice[index] * Number(item.prop2) * multiple + ''
        item.prop9 = orderPrice_oms[index] * Number(item.prop2) * multiple + ''
      }
      if (serviceTypes === '安装') {
        if (item.prop1 === '电动布帘' || item.prop1 === '电动成品帘') {
          if (!addition.includes('电动轨道')) {
            addition.push('电动轨道');
          }
        }
        if (JSON.parse(item.prop3).name === '梦幻帘/垂直帘') {
          if (!addition.includes('梦幻帘')) {
            addition.push('梦幻帘');
          }
        }
        if (JSON.parse(item.prop3).name === '窗帘盒轨道') {
          if (!addition.includes('窗帘盒')) {
            addition.push('窗帘盒');
          }
        }
      }
    })
    data.addition = addition.join(',');
    let newServiceItem = serviceItems.flat(2);
    newServiceItem = newServiceItem.concat(specialServiceItems);
    data.serviceItems = newServiceItem;
    data.serviceDatas = [];
    if (serviceTypes === '安装') {
      data.serviceDatas = postData;
    };
    data.service = serviceTypes;
    data.add_remark = specialRemark;
    data.shop_remark = orderRemark + '✱';
    if (tbRemark) {
      data.name = data.name + 'DD' + tbRemark;
    }

    if (dateType) {
      // if (appointDate !== '' && appointDateTime !== '') {
      // data.appoint_time = appointDate + 'T' + appointDateTime;
      // data.appoint_time = appointDate + 'T' + appointDateTime;
      data.shop_remark = data.shop_remark + '商户指定预约时间为' + appointDate + 'T' + appointDateTime
      // }
    }
    data.add_app = specialPrice;
    data.order_way = '客户下单';
    newServiceItem.map(item => {
      if (item.name === '订单加急') {
        data.urge_order = '是';
      }
      if (item.name === '好评返现') {
        data.bonus_order = '是'
      }
    })
    data.type = '正常订单';
    if (serviceTypes === '安装') {
      let installPic = installVideos.pics.filter(r => r.selected);
      let installVod = installVideos.vod.filter(r => r.selected);
      data.pic_remark = installPic.map(r => r.url);
      data.vod_remark = installVod.map(item => {
        return {
          name: item.name,
          videoId: item.videoId,
        };
      });
    } else {
      let measurePic = measureVideos.pics.filter(r => r.selected);
      let measureVod = measureVideos.vod.filter(r => r.selected);
      data.pic_remark = measurePic.map(r => r.url);
      data.vod_remark = measureVod.map(item => {
        return {
          name: item.name,
          videoId: item.videoId,
        };
      });
    }
    data.pic_remark = data.pic_remark.concat(pics);
    data.pic_remark = JSON.stringify(data.pic_remark);
    if (videoPlay.length > 0) {
      let videos = videoPlay.map(item => {
        return {
          name: item.name,
          videoId: item.videoId,
          isVideo: true,
        };
      })
      data.vod_remark = data.vod_remark.concat(videos);
    }
    data.vod_remark = JSON.stringify(data.vod_remark);

    let serviceRoom = true;
    let serviceWidth = true;
    if (data.serviceDatas.length > 0) {
      data.serviceDatas.forEach(item => {
        if (item.room === '') {
          serviceRoom = false;
        }
        if (item.width === '0') {
          serviceWidth = false;
        }
      })
    }
    if (!serviceRoom && serviceTypes === '安装') {
      this.setState({
        postSpin: false
      })
      message.error('请填写窗帘名称');
      return;
    }
    if (!serviceWidth && serviceTypes === '安装' && types === '窗帘') {
      this.setState({
        postSpin: false
      })
      message.error('请填写长度');
      return;
    }
    let res = await postOrder(methed, data.id, preoder, couponID, data)
    if (res.status !== 200) {
      this.setState({
        postSpin: false
      })
      message.error(res.data);
      return;
    }
    if (preoder) {
      message.success('订单保存至草稿箱')
    } else {
      message.success('订单新建完成')
    }
    if (JSON.stringify(this.props.newOrderData) !== '{}') {
      this.props.fun('订单中心');
    }
    this.setState({
      postSpin: false
    })
    this.onDelForm();
    this.onClickEmptyOrder();
    this.getBalances();
  }

  getBalances = async () => {
    let res = await getFinancebase();
    if (res.status === 200) {
      setBalance(res.data.balance)
    }
    const { dispatch } = this.props;
    dispatch({
      type: 'orderRenter/getNewBalance',
      payload: res.data.balance,
    });
  }

  //② 填写订单信息
  //删除
  ondelData = (index) => {
    let postData = [...this.state.postData];
    let orderPrice = [...this.state.orderPrice];
    let serviceItems = [...this.state.serviceItems];
    let optionsRadio = [...this.state.optionsRadio];
    let groupType = [...this.state.groupType];
    let detailType = [...this.state.detailType];
    let orderPrice_oms = [...this.state.orderPrice_oms];
    let serviceItemG = [...this.state.serviceItemG];
    postData.splice(index, 1);
    orderPrice.splice(index, 1);
    orderPrice_oms.splice(index, 1);
    serviceItems.splice(index, 1);
    optionsRadio.splice(index, 1);
    detailType.splice(index, 1);
    groupType.splice(index, 1);
    serviceItemG.splice(index, 1);
    this.setState({
      postData: postData,
      orderPrice: orderPrice,
      orderPrice_oms: orderPrice_oms,
      serviceItems: serviceItems,
      serviceItemG: serviceItemG,
      optionsRadio: optionsRadio,
      groupType: groupType,
      detailType: detailType,
    })
  }
  //输入框数据
  getInputVaule = (type, index, e) => {
    let value;
    if (type === 'room') {
      value = e + '';
    } else {
      value = e.target.value ? e.target.value + '' : '1';
    }
    let postData = [...this.state.postData];
    postData[index][type] = value;
    this.setState({
      postData: postData
    })
  }
  getRoomModal = (index, name) => {
    this.setState({
      visible: true,
      modalIndex: index,
      roomModalValue: name
    })
  }
  onRoomModalValue = (e) => {
    this.setState({
      roomModalValue: e
    })
  }
  onModalValueChange = (e) => {
    this.setState({
      roomModalValue: e.target.value
    })
  }
  onOk = () => {
    let { modalIndex, roomModalValue } = this.state
    this.getInputVaule('room', modalIndex, roomModalValue)
    this.onCancel()
  }
  //关闭modal
  onCancel = () => {
    this.setState({
      visible: false,
      modalIndex: 0,
    })
  }
  //获取价格
  getPrices = async (index) => {
    this.setState({
      spinningTable: true,
    })
    const { serviceTypes, optionsRadio, detailID, measureID, orderPrice, serviceItems, orderPrice_oms, serviceItemG } = this.state;
    let num = [];
    if (serviceTypes === '测量') {
      num.push(measureID);
    } else {
      let newOptionsRadio = optionsRadio[index];
      for (let i in newOptionsRadio) {
        num.push(Number(newOptionsRadio[i].id))
      }
      if (this.state.types === '其他' || this.state.types === '地板') {
        num.push(measureID);
      } else {
        num.push(detailID[index]);
      }
    }
    let itemid = num.join('|');
    let rsp = await getPrice(itemid, 0, this.shopId);
    if (rsp.status !== 200) {
      message.error(res.data)
      return;
    };
    orderPrice[index] = rsp.data.price_app;
    orderPrice_oms[index] = rsp.data.price_oms;
    if (serviceTypes === '测量') {
      serviceItems[index] = rsp.data.serviceItems;
    }
    if (this.state.types === '窗帘') {
      serviceItemG[index] = rsp.data.serviceItems;
    }
    num = [];
    this.setState({
      orderPrice: orderPrice,
      orderPrice_oms: orderPrice_oms,
      serviceItems: serviceItems,
      serviceItemG: serviceItemG,
      spinningTable: false,
    });
  }
  //获取订单数据
  getCategoryData = async (e) => {
    let res = await getCategory(e, this.shopId);
    if (res.status !== 200) {
      message.error(res.data);
      this.setState({
        spinning: false,
      })
      return;
    }
    this.setState({
      spinning: false,
      data: res.data,
    })
    if (JSON.stringify(this.props.newOrderData) !== '{}') {
      this.getNewOrderDatas(this.props.newOrderData, res.data);
    } else {
      let serviceTypes = '测量';
      if (e === '晾衣架' || e === '地板') {
        serviceTypes = '安装';
      }
      this.getData(serviceTypes, res.data);
    }
  }
  //清空
  onClickEmptyOrder = () => {
    const { videoList } = this.state;
    this.getDiscount();
    this.setState({
      postData: [{ ...serviceDatas }],
      orderPrice: [0],
      orderPrice_oms: [0],
      serviceItems: [],
      serviceItemG: [],
      specialServiceItems: [],
      orderRemark: '',
      specialPrice: 0,
      specialRemark: '',
      dateType: false,
      appointDate: '',
      serviceTypes: '测量',
      measure: '普通测量',
      hasWorker: false,
      noneWorker: false,
      analysisAddress: '',
      otherCheckbox: [],
      otherCheckboxPrice: [],
      otherCheckboxPrice_oms: [],
      tbRemark: '',
      selectedRowKeys: [],
      pics: [],
      videoPlay: [],
    }, async () => this.getCategoryData(this.service[0]))

  }
  //类型切换
  onClickDetail = (type, id, index, e) => {
    if (this.state.datas.grid.length === 0) { return; }
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === type)[0].add;
    let postData = [...this.state.postData];
    postData[index].prop3 = JSON.stringify({ id: id, name: type });
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    detailType[index] = type
    detailID[index] = detail.filter(r => r.name === type)[0].id
    this.setState({
      detailType: detailType,
      detailID: detailID,
    }, async () => this.getPrices(index));
    this.getOptionsRadio(postData, detailAdd, index)
  }
  //品类切换
  onClickGrid = (type, index, e) => {
    let postData = [...this.state.postData];
    if (this.state.datas.grid.length === 0) { return; }
    let detail = this.state.datas.grid.filter(r => r.group === type)[0].detail;
    let detailAdd = detail[0].add;
    postData[index].prop1 = type;
    let groupType = [...this.state.groupType];
    groupType[index] = type
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    detailType[index] = detail[0].name
    detailID[index] = detail[0].id
    this.setState({
      groupType: groupType,
      detailType: detailType,
      detailID: detailID,
    }, async () => this.getPrices(index));
    this.getOptionsRadio(postData, detailAdd, index)
  }
  //选择品类
  onGetOrderType = (type) => {
    let serviceType = ['测量', '安装'];
    let serviceTypes = '测量';
    if (type === '晾衣架') {
      serviceType = ['安装']
      serviceTypes = '安装';
    }
    this.setState({
      types: type,
      serviceType: serviceType,
      serviceTypes: serviceTypes,
      postData: [{ ...serviceDatas }],
      grid: [],
      groupType: [],
      detailType: [],
      detailID: [],
      optionsRadio: [],
    }, async () => this.getCategoryData(type))
    this.getColumns(type);
    // this.getCategoryData(type)
  }

  getColumns = (type) => {
    let columns = [];
    if (type === '窗帘') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '长度（米）',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={0} defaultValue={record.width} onBlur={this.getInputVaule.bind(this, 'width', index)} />
            )
          }
        },
        {
          title: '品类',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            if (this.state.datas.grid.length === 0) { return; }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('层数', index)
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('四角钩', index)
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('墙钩', index)
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('窗幔', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('其他', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ]
    } else if (type === '墙布墙纸') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '材质',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '规格',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('规格', index)
            )
          }
        },
        {
          title: '施工方式',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('施工方式', index)
            )
          }
        },
        {
          title: '施工工艺',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('施工工艺', index)
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('拆旧', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ]
    } else if (type === '晾衣架') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '类型',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            if (!this.state.datas.type.includes('安装')) {
              return;
            }
            let grid = this.state.datas.grid;
            if (grid.length === 0) { return; }
            if (this.state.groupType.length === 0) { return; }
            let detail = grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '驱动方式',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('驱动方式', index)
            )
          }
        },
        {
          title: '安装面',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('安装面', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('拆旧', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ]
    }
    this.setState({
      columns: columns
    }, () => this.getBalances())
  }
  //服务类型
  getData = (type, data) => {
    const { videoList } = this.state;
    this.setState({
      serviceTypes: type,
      orderPrice: [0],
      serviceItems: [],
      orderPrice: [],
      orderPrice_oms: [],
      optionsRadio: [],
      couponData: [],
      specialServiceItems: [],
      installVideos: videoList.install,
      measureVideos: videoList.measure,
      videoPlay: [],
      pics: [],
    }, async () => this.getDiscount());
    let otherCheckbox = [];
    let otherCheckboxPrice = [];
    let otherCheckboxPrice_oms = [];
    if (type === '测量') {
      let serviceData = [{ ...serviceDatas }];
      let dataIndex = 0;
      data.map((ditem, dindex) => {
        if (ditem.type.includes('测量')) {
          dataIndex = dindex
        }
      })
      let measureID = 0;
      let measure = '';
      if (data[dataIndex].grid.length > 0) {
        // measureID = data[dataIndex].grid[0].detail.filter(r => r.name === '普通测量')[0].id;
        measureID = data[dataIndex].grid[0].detail[0].id;
        measure = data[dataIndex].grid[0].detail[0].name;
      }
      data[dataIndex].other.map(item => {
        otherCheckbox.push(false);
        otherCheckboxPrice.push(0)
        otherCheckboxPrice_oms.push(0)
      })
      this.setState({
        postData: serviceData,
        datas: data[dataIndex],
        other: data[dataIndex].other,
        grid: data[dataIndex].grid,
        measureID: measureID,
        measure: measure,
        defaultPrice: data[dataIndex].price,
        otherCheckbox: otherCheckbox,
        otherCheckboxPrice: otherCheckboxPrice,
        otherCheckboxPrice_oms: otherCheckboxPrice_oms,
        fastRemark: data[dataIndex].remarks,
      }, async () => this.getPrices(0))
    } else {
      let dataIndex = 0;
      data.map((ditem, dindex) => {
        if (ditem.type.includes('安装')) {
          dataIndex = dindex
        }
      })
      data[dataIndex].other.map(item => {
        if (['固定金额', '不固定金额'].includes(this.bonus_type) && item.name === '好评返现') {
          otherCheckbox.push(true);
        } else {
          otherCheckbox.push(false);
        }
        otherCheckboxPrice.push(0);
        otherCheckboxPrice_oms.push(0);
      });
      this.setState({
        datas: data[dataIndex],
        other: data[dataIndex].other,
        grid: data[dataIndex].grid,
        groupType: [data[dataIndex].grid[0].group],
        detailType: [data[dataIndex].grid[0].detail[0].name],
        detailID: [data[dataIndex].grid[0].detail[0].id],
        defaultPrice: data[dataIndex].price,
        otherCheckbox: otherCheckbox,
        otherCheckboxPrice: otherCheckboxPrice,
        otherCheckboxPrice_oms: otherCheckboxPrice_oms,
        fastRemark: data[dataIndex].remarks,
      })
      if (this.state.types !== '其他' && this.state.types !== '地板') {
        this.getOptionsRadio([{ ...serviceDatas }], data[dataIndex].grid[0].detail[0].add, 0)
      } else {
        let measureID = 0;
        let measure = '';
        if (data[dataIndex].grid.length > 0) {
          measureID = data[dataIndex].grid[0].detail[0].id;
          measure = data[dataIndex].grid[0].detail[0].name;
        }
        this.setState({
          measureID: measureID,
          measure: measure,
        }, async () => this.getPrices(0))
      }
    }
  }
  //品类数据
  getOptionsRadio = (postData, detailAdd, index) => {
    let optionsRadio = [...this.state.optionsRadio];
    postData.forEach((pitem, pindex) => {
      let newOptionsRadio = {};
      detailAdd.forEach(ditem => {
        newOptionsRadio[ditem.group] = { id: ditem.options[0].id, name: ditem.options[0].name };
      });
      optionsRadio.splice(index, 1, newOptionsRadio)
    })
    this.setState({
      optionsRadio: optionsRadio,
    }, async () => this.changePostData(postData, index))
  }
  //table数据
  changePostData = (data, index) => {
    if (this.state.datas.grid.length === 0) { return; }
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === this.state.detailType[index])[0].add;
    let prop3ID = detail.filter(r => r.name === this.state.detailType[index])[0].id
    const { groupType, detailType } = this.state;
    data[index].prop1 = groupType[index];
    data[index].prop3 = JSON.stringify({ id: prop3ID, name: detailType[index] });
    data[index].prop4 = '';
    data[index].prop5 = '';
    data[index].prop6 = '';
    data[index].prop7 = '';
    data[index].prop8 = '';
    data[index].prop9 = '';
    detailAdd.forEach(item => {
      switch (item.group) {
        case this.getCaseType(this.state.types, 'prop4'):
          data[index].prop4 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.types, 'prop5'):
          data[index].prop5 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.types, 'prop6'):
          data[index].prop6 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.types, 'prop7'):
          data[index].prop7 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.types, 'prop8'):
          data[index].prop8 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.types, 'prop9'):
          data[index].prop9 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        default:
          break;
      }
    })
    this.setState({
      postData: data,
    }, async () => this.getPrices(index))
  }
  //测量类型
  onMeasureChange = (e) => {
    if (this.state.datas.grid.length === 0) { return; }
    let measureID = this.state.datas.grid[0].detail.filter(r => r.name === e.target.value)[0].id;
    this.setState({
      measure: e.target.value,
      measureID: measureID,
    }, async () => this.getPrices(0))
  }
  //table品类
  tableType = (itemType, index) => {
    if (JSON.stringify(this.state.datas) === '{}') {
      return;
    }
    if (!this.state.optionsRadio[index]) {
      return;
    }
    if (this.state.datas.grid.length === 0) { return; }
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === this.state.detailType[index])[0].add;
    let options = detailAdd.filter(r => r.group === itemType);
    let optionsRadio = this.state.optionsRadio[index];
    return (
      <div className='col'>
        {
          options.length === 0 ? <div className={styles['options0']}></div> : <Radio.Group value={optionsRadio[itemType].id} className='row-w'>
            {
              options[0].options.map((oItem, oIndex) => {
                return (
                  <Radio value={oItem.id} key={oIndex} onChange={this.onChangeRadio.bind(this, oItem.name, oItem.id, itemType, index)}>{oItem.name}</Radio>
                )
              })
            }
          </Radio.Group>
        }
      </div>
    )
  }

  getCaseType = (types, prop) => {
    let caseType = '';
    if (types === '窗帘') {
      switch (prop) {
        case 'prop4':
          caseType = '层数';
          break;
        case 'prop5':
          caseType = '四角钩';
          break;
        case 'prop6':
          caseType = '墙钩';
          break;
        case 'prop7':
          caseType = '窗幔';
          break;
        case 'prop8':
          caseType = '超高';
          break;
        case 'prop9':
          caseType = '其他';
          break;
        default:
          break;
      }
    } else if (types === '墙布墙纸') {
      switch (prop) {
        case 'prop4':
          caseType = '规格';
          break;
        case 'prop5':
          caseType = '施工方式';
          break;
        case 'prop6':
          caseType = '施工工艺';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    } else if (types === '晾衣架') {
      switch (prop) {
        case 'prop4':
          caseType = '驱动方式';
          break;
        case 'prop5':
          caseType = '安装面';
          break;
        case 'prop6':
          caseType = '超高';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    }
    return caseType;
  }

  //table品类切换
  onChangeRadio = (name, id, type, index, e) => {
    let optionsRadio = [...this.state.optionsRadio];
    let postData = [...this.state.postData];
    optionsRadio[index][type] = { id, name };
    switch (type) {
      // case '层数':
      case this.getCaseType(this.state.types, 'prop4'):
        postData[index].prop4 = JSON.stringify({ id, name });
        break;
      // case '四角钩':
      case this.getCaseType(this.state.types, 'prop5'):
        postData[index].prop5 = JSON.stringify({ id, name });
        break;
      // case '墙钩':
      case this.getCaseType(this.state.types, 'prop6'):
        postData[index].prop6 = JSON.stringify({ id, name });
        break;
      // case '窗幔':
      case this.getCaseType(this.state.types, 'prop7'):
        postData[index].prop7 = JSON.stringify({ id, name });
        break;
      // case '超高':
      case this.getCaseType(this.state.types, 'prop8'):
        postData[index].prop8 = JSON.stringify({ id, name });
        break;
      // case '其他':
      case this.getCaseType(this.state.types, 'prop9'):
        postData[index].prop9 = JSON.stringify({ id, name });
        break;
      default:
        break;
    }
    this.setState({
      optionsRadio: optionsRadio,
      postData: postData
    }, async () => this.getPrices(index))
  }
  //订单加急，好评
  onOtherChange = (id, name, index, e) => {
    let { otherCheckbox } = this.state;
    otherCheckbox[index] = e.target.checked;
    this.setState({
      otherCheckbox: otherCheckbox
    }, async () => this.getSpecialPrices(name, id, index, e.target.checked));
  }
  //获取特殊费用
  getSpecialPrices = async (name, id, index, e) => {
    let { otherCheckboxPrice, otherCheckboxPrice_oms, specialServiceItems } = this.state;
    let indexs = specialServiceItems.findIndex(r => r.name === name);
    if (e) {
      let res = await getPrice(id, 0, this.shopID);
      if (res.status !== 200) {
        message.error(res.data)
        return;
      }
      specialServiceItems.push(res.data.serviceItems[0])
      otherCheckboxPrice[index] = res.data.price_app;
      otherCheckboxPrice_oms[index] = res.data.price_oms;
    } else {
      otherCheckboxPrice[index] = 0;
      otherCheckboxPrice_oms[index] = 0;
      specialServiceItems.splice(indexs, 1);
    }
    this.setState({
      otherCheckboxPrice: otherCheckboxPrice,
      otherCheckboxPrice_oms: otherCheckboxPrice_oms,
      specialServiceItems: specialServiceItems,
    })
  }
  //是否指定预约时间
  onDateChange = (e) => {
    this.setState({
      dateType: e.target.checked
    })
  }
  //指定时间
  getDateChange = (dates, dateStrings) => {
    this.setState({
      appointDate: dateStrings
    })
  }
  onTimeChange = (time, timeString) => {
    this.setState({
      appointDateTime: timeString
    })
  }
  //
  onAddData = () => {
    let postData = [...this.state.postData];
    const { groupType, detailType, datas, detailID } = this.state;
    let newData = { ...serviceDatas };
    newData.room = `房间${postData.length + 1}`;
    newData.sort = postData.length;
    postData.push(newData);
    groupType.push(datas.grid[0].group)
    detailType.push(datas.grid[0].detail[0].name)
    detailID.push(datas.grid[0].detail[0].id)
    this.setState({
      postData: postData,
      groupType: groupType,
      detailType: detailType,
      detailID: detailID,
    })
    this.getOptionsRadio(postData, this.state.datas.grid[0].detail[0].add, postData.length - 1)
  }
  //订单备注
  onShopRemark = (item, e) => {
    let orderRemark = this.state.orderRemark;
    let newItem = item === '' ? e.target.value : orderRemark + item + '\n';
    this.setState({
      orderRemark: newItem,
    })
  }
  onTbRemark = (e) => {
    let tbRemark = e.target.value;
    tbRemark = tbRemark.replace(/[^\w\u4e00-\u9fa5]/g, '')
    this.setState({
      tbRemark
    });
  }

  throttled = (fn, delay = 1000) => {
    let preTiem = Date.now();
    return (event) => {
      const context = this;
      event.persist && event.persist() //保留对事件的引用
      let doTime = Date.now();
      if (doTime - preTiem >= delay) {
        fn.apply(context);
        preTiem = Date.now()
      }
    }
  }
  //① 填写客户信息
  onAnalysisAddressValue = (e) => {
    this.setState({
      analysisAddress: e.target.value,
    })
  }
  //自动识别
  onAnalysisAddress = this.throttled(async (e) => {
    // let inputEl = document.querySelector('[name="analysisAddress"]');
    // let val = inputEl.value.replace(/[\r\n]/g, "");
    let val = this.state.analysisAddress;
    if (val === '') {
      message.warning('自动识别客户信息不能为空')
      return;
    }
    let res = await analysisAddress(val)
    if (res.status !== 200) {
      message.error(res.data)
      return;
    }
    let noneWorker = !res.data.hasWorker;
    this.setState({
      customerData: res.data,
      hasWorker: res.data.hasWorker,
      price: res.data.disFee,
      minDistance: res.data.minDistance,
      noneWorker
      // analysisAddress: val
    })
    this.form.current.setFieldsValue({ ...res.data });
  })
  //清空客户信息
  onDelForm = () => {
    this.setState({
      customerData: customerDataInit,
      transferShow: false,

    })
    this.form.current.setFieldsValue({ ...customerDataInit });
  }
  //输入框
  onCusromerChange = (type, e) => {
    let customerData = { ...this.state.customerData }
    customerData[type] = e.target.value
    this.setState({
      customerData: customerData
    })
    // this.form.current.setFieldsValue({...customerData});
  }
  //省市区
  onReginChange = (e) => {
    let customerData = { ...this.state.customerData }
    customerData.province = e[0];
    customerData.city = e[1];
    customerData.district = e[2];
    this.setState({
      customerData: customerData
    })
  }
  //查询测量单
  onSearchConnect = async () => {
    const { customerData, orderPrice_oms } = this.state;
    if (!customerData.phone) {
      return;
    }
    let res = await getMeasureDataList(customerData.phone, 0, 0, 10);
    // let res = await getMeasureDataList({ info: '13683046654', skip: 0, take: 20 });
    if (res.status === 200) {
      this.setState({
        transferList: res.data,
        transferShow: true,
      });
    }
  }
  //查询测量单数据
  onExcelData = async (id) => {
    let res = await getExcel(id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let newFileUrl = encodeURIComponent(res.data)
    window.open(`https://view.officeapps.live.com/op/view.aspx?src=${newFileUrl}`, '_blank')
  }
  transferOrder = async (id) => {
    const { types, serviceTypes, datas } = this.state;
    if (types !== '窗帘' || serviceTypes !== '安装') {
      message.error('当前类型不支持转单');
      return;
    }
    let rsp = await getOrder(id);
    if (rsp.status !== 200) { return; }
    let customerData = { ...this.state.customerData };
    customerData.receiver = rsp.data.name;
    customerData.province = rsp.data.province;
    customerData.city = rsp.data.city;
    customerData.district = rsp.data.district;
    customerData.address = rsp.data.address;
    this.setState({
      customerData,
    });
    this.form.current.setFieldsValue({ ...customerData });
    let postData = [...this.state.postData];
    let groupType = [...this.state.groupType];
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    let optionsRadio = [...this.state.optionsRadio];
    let orderPrice = [...this.state.orderPrice];
    let orderPrice_oms = [...this.state.orderPrice_oms];
    let serviceItems = [...this.state.serviceItems];
    let serviceItemG = [...this.state.serviceItemG];
    rsp.data.serviceDatas.map(async (item, index) => {
      let newData = { ...serviceDatas };
      let newroom = item;
      newData.sort = postData.length;
      newData.room = item.room;
      newData.height = item.height === '0' ? '1' : item.height;
      newData.height_remark = item.height_remark;
      newData.width = item.width === '0' ? '1' : item.width
      newData.width_remark = item.width_remark;
      postData.push(newData)
      let position = postData.length - 1
      groupType.push(datas.grid[0].group)
      let temporaryType = 0;
      datas.grid[0].detail.map((fitem, findex) => {
        if (fitem.name === item.prop1) {
          temporaryType = findex;
        }
      })
      detailType.push(datas.grid[0].detail[temporaryType].name)
      detailID.push(datas.grid[0].detail[temporaryType].id)
      datas.grid[0].detail[temporaryType].add.forEach((pitem, pindex) => {
        let newOptionsRadio = {};
        datas.grid[0].detail[temporaryType].add.forEach(ditem => {
          let temporaryPlies = 0;
          if (ditem.group === '层数' && item.prop5 == '双层') {
            ditem.options.map((titem, tindex) => {
              if (titem.name === '双层') {
                temporaryPlies = tindex;
              }
            })
          } else if (ditem.group === '超高' && item.prop10 == '是') {
            ditem.options.map((titem, tindex) => {
              if (titem.name === '是' || titem.name === '有') {
                temporaryPlies = tindex;
              }
            })
          } else if (ditem.group === '其他' && item.prop11 == '是') {
            ditem.options.map((titem, tindex) => {
              if (titem.name === '拆旧') {
                temporaryPlies = tindex;
              }
            })
          }
          newOptionsRadio[ditem.group] = { id: ditem.options[temporaryPlies].id, name: ditem.options[temporaryPlies].name };
        });
        optionsRadio.splice(position, 1, newOptionsRadio)
      })
      let detail = datas.grid.filter(r => r.group === groupType[position])[0].detail;
      let detailAdd = detail.filter(r => r.name === detailType[position])[0].add;
      let prop3ID = detail.filter(r => r.name === detailType[position])[0].id
      postData[position].prop1 = groupType[position];
      postData[position].prop3 = JSON.stringify({ id: prop3ID, name: detailType[position] });
      postData[position].prop4 = '';
      postData[position].prop5 = '';
      postData[position].prop6 = '';
      postData[position].prop7 = '';
      postData[position].prop8 = '';
      postData[position].prop9 = '';
      detailAdd.forEach(item => {
        switch (item.group) {
          case this.getCaseType(this.state.types, 'prop4'):
            let floor = 0;
            if (newroom.prop5 == '双层') {
              item.options.map((item, index) => {
                if (item.name === '双层') {
                  floor = index;
                }
              });
            }
            postData[position].prop4 = JSON.stringify({ id: item.options[floor].id, name: item.options[floor].name });
            // postData[position].prop4 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
            break;
          case this.getCaseType(this.state.types, 'prop5'):
            postData[position].prop5 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
            break;
          case this.getCaseType(this.state.types, 'prop6'):
            postData[position].prop6 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
            break;
          case this.getCaseType(this.state.types, 'prop7'):
            postData[position].prop7 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
            break;
          case this.getCaseType(this.state.types, 'prop8'):
            let more = 0;
            if (newroom.prop10 == '是') {
              item.options.map((item, index) => {
                if (item.name === '是' || item.name === '有') {
                  more = index;
                }
              });
            }
            postData[position].prop8 = JSON.stringify({ id: item.options[more].id, name: item.options[more].name });
            // postData[position].prop8 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
            break;
          case this.getCaseType(this.state.types, 'prop9'):
            let dismantl = 0;
            if (newroom.prop11 == '是') {
              item.options.map((item, index) => {
                if (item.name === '拆旧') {
                  dismantl = index;
                }
              });
            }
            postData[position].prop9 = JSON.stringify({ id: item.options[dismantl].id, name: item.options[dismantl].name });
            // postData[position].prop9 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
            break;
          default:
            break;
        }
      })
      // ------------------------------------
      let num = [];
      let newOptionsRadio = optionsRadio[position];
      for (let i in newOptionsRadio) {
        num.push(Number(newOptionsRadio[i].id))
      }
      num.push(detailID[position]);
      let itemid = num.join('|');
      let res = await getPrice(itemid, 0, this.shopId);
      if (res.status !== 200) {
        message.error(res.data)
        return;
      };
      orderPrice[position] = res.data.price_app;
      orderPrice_oms[position] = res.data.price_oms;
      serviceItemG[position] = res.data.serviceItems;
      num = [];
      // ------------------------------------------------------
      this.setState({
        postData: postData,
        groupType: groupType,
        detailType: detailType,
        detailID: detailID,
        optionsRadio: optionsRadio,
        orderPrice: orderPrice,
        orderPrice_oms: orderPrice_oms,
        serviceItems: serviceItems,
        serviceItemG: serviceItemG,
      }, () => this.ondelData(0));
    })
    // this.ondelData(0)
  }
  //修改快速备注
  EditRemark = () => {
    const { fastRemark, types, serviceTypes, data } = this.state;
    let type = types + serviceTypes;
    this.remarkModal.current.onShow(this.shopId, type, fastRemark);
  }
  updataRemark = (newRemark, type) => {
    let newData = [...this.state.data];
    let index = newData.findIndex(item => item.type === type);
    newData[index].remarks = newRemark;
    this.setState({
      data: newData,
      fastRemark: newRemark,
    });
  }
  //优惠券弹框
  onCancelCoupon = () => {
    this.setState({
      couponModal: false,
      selectedRowKeys: [],
    })
  }
  //判断是否有可用优惠券
  judgeCoupon = () => {
    const { couponData, customerData } = this.state;
    if (customerData.receiver === '') {
      message.error('请填写客户名称');
      return;
    }
    if (customerData.phone === '') {
      message.error('请填写客户手机号');
      return;
    }
    if (customerData.phone.length !== 11) {
      message.error('手机号不正确');
      return;
    }
    if (customerData.address === '') {
      message.error('请填写客户详细地址');
      return;
    }
    if (couponData.length > 0) {
      this.setState({
        couponModal: true,
      })
    } else {
      this.onPostClick(false)
    }
  }
  onSelectCoupon = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
    });
  }
}

// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);