import request from '../utils/request';

export async function getOrderList(data) {
  return await request(`shopBiz/shoporder?user=${data.user}&search=${data.search}&info=${data.info}&start=${data.start}&end=${data.end}&category=${data.category}&service=${data.service}&state=${data.state}&types=${data.types}&skip=${data.skip}&take=${data.take}`, 'get');
}

export async function getOrder(orderid) {
  return await request(`shopbiz?orderid=${orderid}`, 'get')
}

export async function putOrder(orderid, methed, data) {
  return await request(`shopbiz?orderid=${orderid}&methed=${methed}&couponid=${0}`, 'put', data)
}

export async function postOrderSecond(orderid, remark, data) {
  return await request(`shopbiz/second?orderid=${orderid}&remark=${remark}`, 'post')
}

export async function postOrderClaim(orderid, type, price, remark) {
  return await request(`shopbiz/claim?orderid=${orderid}&type=${type}&price=${price}&remark=${remark}`, 'post')
}

export async function getExcel(orderid) {
  return await request(`data/excelurl?orderid=${orderid}`, 'get')
}

//催单
export async function putUrgecontact(data) {
  return await request('platbiz/urgecontact', 'put', data);
}

//订单导出
export async function orderToEacel(data) {
  return await request(`shopbiz/orderexport?user=${data.user}&search=${data.search}&info=${data.info}&start=${data.start}&end=${data.end}&category=${data.category}&service=${data.service}&state=${data.state}&types=${data.types}`, 'get');
}

//删除草稿箱
export async function delDraft(orderid) {
  return await request(`shopbiz?orderid=${orderid}`, 'delete');
}
//审核订单
export async function putBonus(id, state, price) {
  return await request(`shopbiz/checkbonus?id=${id}&state=${state}&price=${price}`, 'put');
}

//订单详情，免accession
export async function getOrderInfo(orderid, shop) {
  return await request(`open/order?orderid=${orderid}&shop=${shop}`, 'get');
}